import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, auth, address, ...rest }) => (
  <Route
    {...rest}
    render={props => auth || address ? (<Component {...props} />) : (<Redirect to="/" />)}
  />
);

PrivateRoute.propTypes = {
  auth: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
};

const mapStateToProps = ({ Auth }) => ({
  auth: Auth.auth,
  address: Auth.address
});

export default connect(mapStateToProps)(PrivateRoute);
