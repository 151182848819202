import { create as ipfsHttpClient } from 'ipfs-http-client';
import axios from 'axios';
const client = ipfsHttpClient('https://ipfs.infura.io:5001/api/v0');

export const CreateIPFS = (data) => {
    return new Promise(async (resolve, reject)=>{
            const added = await client.add(data);
            const uri = `https://ipfs.io/ipfs/${added['path']}`;
          return resolve(uri);
    })
};

export const decodeIPFS = (uri) => {
  return new Promise(async (resolve, reject)=> {
    // console.log("**************** uri", uri)

  //  let fetchIpsf = await fetch(uri);
      // .then(response => 
    // console.log("**************** response", fetchIpsf)
        
        // )
      // .then(response => resolve(response))
      // .catch(error => reject(error));
  });
}