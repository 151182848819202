import EventBus from 'eventing-bus';
import { connect } from "react-redux";
import { Redirect } from 'react-router';
import React, { Component } from 'react';
import { TextField, InputBase } from '@material-ui/core';
import { Send, CheckCircle, Info, Error, Done, Facebook, Twitter, Instagram, LinkedIn, LiveTvRounded } from '@material-ui/icons';

import './index.css';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { updateUserProfile } from '../../store/actions/Auth'
import { CreateIPFS } from '../../store/helper';


class Edit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            bio: '',
            file: '',
            email: '',
            imageNew: '',
            customurl: '',
            displayname: '',
            twitterName: '',
            instagramName: '',
            imageUploaded: '',
            selectedFile: null,
            personalProfile: '',
        };
        this.handleUploadProfileImage = this.handleUploadProfileImage.bind(this)
    };

    handleUploadProfileImage = async (event) => {
        try {
            let file = event.target.files[0];
            let bytes = file.size;
            let name = file.name;
            if (bytes > 1e+8) return EventBus.publish('error', 'Error: Please Uplaod lessthen 100 MB');
            let uri = await CreateIPFS(file);
            this.setState({ selectedFile: uri });
            EventBus.publish('success', 'Profile Image Uploaded');
        } catch (error) {
            EventBus.publish('error', 'Error uploading file: ', error);
        }
    }


    SubmitForm = () => {
        const avatar = new FormData();
        avatar.append('avatar', this.state.file);

        // Details of the uploaded file
        const { displayname, customurl, bio, twitterName, instagramName, personalProfile, email, file ,selectedFile } = this.state
        this.props.updateUserProfile({ file: this.state.file, publicaddress: this.props.address, name: displayname, url: customurl, bio: bio, twittername: twitterName, instagramName: instagramName, portfolio: personalProfile, email: email, imageUrl: selectedFile });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.userprofileData !== this.props.userprofileData) {
            const { name, customeurl, email, bio, twitterName, instagramName, portfolioLink, avatar, profileImageUrl } = this.props.userprofileData
            this.setState({ selectedFile: avatar, displayname: name !== "undefined" ? name : '', customurl: customeurl !== "undefined" ? customeurl : '', twitterName: twitterName !== "undefined" ? twitterName : '', instagramName: instagramName !== "undefined" ? instagramName : '', email: email !== "undefined" ? email : '', bio: bio !== "undefined" ? bio : '', personalProfile: portfolioLink !== "undefined" ? portfolioLink : '' })
        }
    } 
    componentDidMount(){
        const { name, customeurl, email, bio, twitterName, instagramName, portfolioLink, avatar, profileImageUrl } = this.props.userprofileData
        this.setState({ selectedFile: profileImageUrl, displayname: name !== "undefined" ? name : '', customurl: customeurl !== "undefined" ? customeurl : '', twitterName: twitterName !== "undefined" ? twitterName : '', instagramName: instagramName !== "undefined" ? instagramName : '', email: email !== "undefined" ? email : '', bio: bio !== "undefined" ? bio : '', personalProfile: portfolioLink !== "undefined" ? portfolioLink : '' })
    }

    handleUploadClick = (event) => {
        this.setState({ file: event.target.files[0] });
        let reader = new FileReader();
        let file = event.target.files[0];

        reader.onloadend = () => {
            this.setState({
                imageNew: reader.result,
                selectedFile: reader.result,
            });
        }
        reader.readAsDataURL(file);
    };
    

    render() {
        let { address , userprofileData} = this.props;
        let { displayname, customurl, bio, twitterName, instagramName, personalProfile, email } = this.state;
        if (!address) return <Redirect to="/" />
        return (
            <div className='edit-nft'>
                <Navbar />

                {/* Header Section */}
                {/* <section className="header-section">
                    <img src={require("../../static/images/landing/landingnft/back-grey.png")} className="back" alt="" />
                </section> */}

                <section className="edit-profile" style={{ backgroundImage: `url(${require("../../static/images/landing/landingnft/profile/profile-bg.png")})` }}>
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-12">
                                <div className="editprofile-area">
                                    <div className="sec-title">
                                        <h2>Settings</h2>
                                        {/* <p>Tocaya decided to launch this 1 of 1 NFT as a way to use their platform to showcase the ability to take advantage of state of the art technology (in this case NFTs) in.</p> */}
                                    </div>
                                    <div className="card-tab-menu">

                                        <button type="button" className="active">Profile<i class="icon fa fa-image"></i></button>


                                    </div>

                                    <div className="edit-area">
                                        <div className="row clearfix">
                                            <div className="col-lg-7 col-md-12 col-12 order-lg-first order-md-first order-sm-last order-last">

                                                <div className="contact-form">
                                                    <form>
                                                        <div className="row">
                                                            <div className="col-lg-12 col-md-12">
                                                                <div className="group-form">
                                                                    {/* <label for="html">Display Name</label> */}
                                                                    <TextField
                                                                        fullWidth
                                                                        value={displayname}
                                                                        placeholder="Enter Username *"
                                                                        margin="normal"
                                                                        id="standard-name"
                                                                        onChange={(e) => this.setState({ displayname: e.target.value })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12 col-md-12">
                                                                <div className="group-form">
                                                                    <TextField
                                                                        fullWidth
                                                                        placeholder="Add something about yourself"
                                                                        margin="normal"
                                                                        id="standard-name"
                                                                        value={bio}
                                                                        onChange={(e) => this.setState({ bio: e.target.value })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12 col-md-12">
                                                                <div className="group-form">
                                                                    <TextField
                                                                        fullWidth
                                                                        placeholder="Enter email address *"
                                                                        margin="normal"
                                                                        id="standard-name"
                                                                        value={email}
                                                                        onChange={(e) => this.setState({ email: e.target.value })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12 col-md-12">
                                                                <h3>Links</h3>
                                                            </div>
                                                            <div className="col-lg-12 col-md-12">
                                                                <div className="group-form">
                                                                    <TextField
                                                                        fullWidth
                                                                        placeholder="Your Twitter handle"
                                                                        margin="normal"
                                                                        id="standard-name"
                                                                        value={twitterName}
                                                                        onChange={(e) => this.setState({ twitterName: e.target.value })}
                                                                    />
                                                                    <i className="icon"><img src={require("../../static/images/landing/landingnft/profile/twitter-form.png")} alt="" /></i>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12 col-md-12">
                                                                <div className="group-form">
                                                                    <TextField
                                                                        fullWidth
                                                                        placeholder="Your Instagram handle"
                                                                        margin="normal"
                                                                        id="standard-name"
                                                                        value={instagramName}
                                                                        onChange={(e) => this.setState({ instagramName: e.target.value })}
                                                                    />
                                                                    <i className="icon"><img src={require("../../static/images/landing/landingnft/profile/instagram-form.png")} alt="" /></i>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12 col-md-12">
                                                                <div className="group-form">
                                                                    <TextField
                                                                        fullWidth
                                                                        placeholder="Your website"
                                                                        margin="normal"
                                                                        id="standard-name"
                                                                        value={customurl}
                                                                        onChange={(e) => this.setState({ customurl: e.target.value })}
                                                                    />
                                                                    <i className="icon"><img src={require("../../static/images/landing/landingnft/profile/web-form.png")} alt="" /></i>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-12 col-md-12">
                                                                <h3>Wallet address</h3>
                                                            </div>

                                                            <div className="col-lg-12 col-md-12">
                                                                <div className="group-form">
                                                                    <TextField
                                                                        fullWidth
                                                                        placeholder="0Xf76a86adg96adg7ad6f78adf6a8a6f"
                                                                        margin="normal"
                                                                        id="standard-name"
                                                                        value={address}
                                                                       // onChange={(e) => this.setState({ walletName: e.target.value })}
                                                                    />
                                                                    <i className="icon"><img src={require("../../static/images/landing/landingnft/profile/wallet-form.png")} alt="" /></i>
                                                                </div>
                                                            </div>

                                                            {/* <div className="col-lg-12 col-md-12">
                                                                <label for="html">Personal site or Portfolio</label>
                                                                <TextField
                                                                    className="contact-formss"
                                                                    fullWidth
                                                                    placeholder="behance.com/your profile link"
                                                                    margin="normal"
                                                                    id="standard-name"
                                                                    value={personalProfile}
                                                                    onChange={(e) => this.setState({ personalProfile: e.target.value })}
                                                                />
                                                            </div> */}

                                                        </div>
                                                    </form>
                                                </div>
                                                {/* <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="verification">
                                                            <h5>Verification</h5>
                                                            <p>Tocaya decided to launch this 1 of 1 NFT as a way to use their platform to showcase </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="button-verfication">
                                                            <button type="button">Set Verified</button>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className="update-your-profile">


                                                    <button type="button" onClick={() => this.SubmitForm()} className="btn-style-one">Save <i className='icon'><img src={require("../../static/images/landing/landingnft/profile/save-form.png")} alt="" /></i></button>
                                                </div>
                                            </div>
                                            <div className="col-lg-5 col-md-12 col-12 order-lg-last order-sm-last order-sm-first order-first">
                                                <div className="main-right-side-logo">
                                                    <h3>Profile image</h3>
                                                    <div className="preview-button">
                                                        <label htmlFor="select-image">
                                                            <label class="btn-style-one">
                                                                <input style={{ display: 'none' }} required type="file" onChange={this.handleUploadProfileImage} accept="image/png, image/jpg, image/jpeg, image/gif" />
                                                                Preview<i className='icon'><img src={require("../../static/images/landing/landingnft/profile/preview-for.png")} alt="" /></i>
                                                            </label>
                                                            {/* <button type="button"   className="btn-style-one">Preview <i className='icon'><img src={require("../../static/images/landing/landingnft/profile/preview-for.png")} alt="" /></i></button> */}
                                                        </label>
                                                    </div>
                                                    <div className="icon">
                                                        <img src={this.state.selectedFile ? this.state.selectedFile : require("../../static/images/landing/landingnft/profile/eth-2.png")} className="back" alt="" />
                                                    </div>
                                                    {/* <h5>Tocaya <span>@tocaya</span></h5>
                                                    <p>Tocaya decided to launch this 1 of 1 NFT as a way to use their platform to</p>
                                                    <input type="file" className="custom-file-inputt" name="avatar" onChange={this.handleUploadClick} id="contained-button-file" /> */}

                                                    <h3>Profile Banner</h3>
                                                    <div className="icon banner-icon">
                                                        <img src={this.state.selectedFile ? this.state.selectedFile : require("../../static/images/landing/landingnft/profile/banner-previw.png")} className="back" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        );
    }
}


const mapDispatchToProps = { updateUserProfile };

const mapStateToProps = ({ Auth }) => {

    let { address, UpdatedProfile, userprofileData } = Auth;
    return { address, UpdatedProfile, userprofileData }
};


export default connect(mapStateToProps, mapDispatchToProps)(Edit);