let initialState = {
  token: "",
  types: [],
  getNft: [],
  hotBids: {},
  profiles: [],
  isToggle: {},
  singleNFT: {},
  allNFTData: [],
  categories: [],
  collectionData: {},
  allCollections: [],
  selectedCollection: {},
  singleCollection:{},
  setMyCollections: [],
  setBasicCollectionDetails: {},
  setLoader: { message: 'Please wait...', status: false },
  userCollectedNFTs: [],
  userFavNFTs: [],
  userCreatedNFTs: [],
  userActivity: [],
  nftData: {},
  nftDataMarketplace: {},
  collectionsMarketplace: [],
  likedNftMarketplace: [],
  setCategories:[]
};

const NFT = (state = initialState, { type, payload }) => {
  switch (type) {
    case "SET_CATEGORIES":
      return {
        ...state,
        setCategories: payload,
      };

    case "SET_SINGLE_NFT":
      return {
        ...state,
        singleNFT: payload,
      };

    case "SET_CREATE_NFT":
      return {
        ...state,
        getNft: payload,
      };

    case "SET_LOADER":
      return {
        ...state,
        setLoader: payload,
      };

    case "SET_TOKEN":
      return {
        ...state,
        token: payload,
      };

    case "CREATE_ORDER_API":
      return {
        ...state,
        token: payload,
      };

    case "GET_ISLIKED":
      return {
        ...state,
        isToggle: payload,
      };

    case "SET_HOT_BIDS_DATA":
      return {
        ...state,
        hotBids: payload,
      };

    /* These are all user collections */
    case "SET_COLLECTION_DATA":
      return {
        ...state,
        categories: payload["categories"],
        allCollections: payload["collections"],
      };

    /* This is to select a collection */
    case "SELECT_COLLECTION":
      return {
        ...state,
        selectedCollection: payload,
      };

    case 'SET_SINGLE_COLLECTION':
      return {
        ...state,
        singleCollection:payload
      }
    
    case 'SET_MY_COLLECTIONS':
      return {
        ...state,
        setMyCollections:payload
      }
    
    case 'SET_BASIC_COLLECTION_DETAILS':
      return {
        ...state,
        setBasicCollectionDetails:payload
      }

    /* Profile page */

    case 'SET_USER_COLLECTED_NFTS':
      return {
        ...state,
        userCollectedNFTs: payload
      }

    case 'SET_USER_CREATED_NFT':
      return {
        ...state,
        userCreatedNFTs: payload
      }

    case 'SET_USER_FAV_NFTS':
      return {
        ...state,
        userFavNFTs: payload
      }

    case 'SET_USER_ACTIVITY':
      return {
        ...state,
        userActivity: payload
      }
    
    case "SET_NFT_DATA":
      return {
        ...state,
        nftData: payload,
      };
  
    case "SET_NFT_DATA_MARKETPLACE":
      return {
        ...state,
        nftDataMarketplace: payload,
      };

    case "SET_ALL_COLLECTIONS":
      return {
        ...state,
        collectionsMarketplace: payload,
      };

    case "SET_LIKED_NFTS":
      return {
        ...state,
        likedNftMarketplace: payload,
      };

    default:
      return state;
    }
  };
export default NFT;