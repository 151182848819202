import axios from 'axios';
import EventBus from 'eventing-bus';
import { all, takeEvery, call, put } from 'redux-saga/effects';
import jwt_decode from 'jwt-decode';

import { setAddress, setUserData, setUpdateUserProfile, saveloginData } from '../actions/Auth';
import { web3 } from '../web3';


// function* login({ payload }) {
//   try {
//     const { error, response } = yield call(postCall, { path: `/users/login`, payload: payload });
//     if (response) {
//       if (response) {
//         yield put(setAddress(payload['publicAddress']));
//         EventBus.publish("success", response['data']['message']);
//       }
//       else EventBus.publish("error", error['data']['message'])
//     }
//   }
//   catch (e) { yield put({ type: "IS_LOGGED_IN", payload: false }); }
// };


/*========== NONCE FUNCTIONS =============*/

function* login({ payload, history }) {
  const { error, response } = yield call(getCall, `/users/getNonce/${payload['publicAddress']}`);
  if (error) {
    yield put({ type: "IS_LOGIN_DISABLED" });
    EventBus.publish('error', error['response']['data']['message']);
  }
  else if (response) {
    var nonceObj = response['data']['body']['nonceObject'];
    // SIGING THE NONCE
    try {
      const signature = yield web3.eth.personal.sign(web3.utils.utf8ToHex(`Login Nonce: ${nonceObj["nonce"]}`), payload['publicAddress'])
      let data = {
        signature,
        publicAddress: payload['publicAddress'],
      }

      let metaMaskResponse = yield call(postCall, { path: `/users/loginWithMetaMask`, payload: data });
      if (error) {
        yield put({ type: "IS_LOGIN_DISABLED" });
        EventBus.publish("error", error['response']['data']['message']);
      }
      else if (metaMaskResponse) {
        let { token } = metaMaskResponse['response']['data']['body']
        const decoded = jwt_decode(token, { header: false });
        let role = decoded["role"];
        if (role !== "user") {
          EventBus.publish("error", "Can't login through other account");
          yield put({ type: "IS_LOGIN_DISABLED" });
          return;
        }
        let data = { token, role }
        yield put(saveloginData(data));
        yield put({ type: "IS_LOGIN_DISABLED" });

        EventBus.publish("success", metaMaskResponse['response']['data']['message'])
        // setTimeout(() => history.push('/home'), 1000);
        // console.log(`****** publicAddress`, payload['publicAddress'])
        yield put(setAddress(payload['publicAddress']));


      }
    } catch (e) { yield put({ type: "IS_LOGIN_DISABLED" }); }
  }
};


function* updateUserProfile({ payload }) {
  try {
    const { error, response } = yield call(putCallWithFile, { path: `/users/update`, payload: payload });
    if (response) {
      yield put(setUpdateUserProfile(response));
      EventBus.publish("success", response['data']['message'])
    } else {
      EventBus.publish("error", error['data']['message'])
    }
  } catch (e) { yield put({ type: "IS_LOGGED_IN", payload: false }); }
}


function* getUserData({ payload }) {
  try {
    const { error, response } = yield call(getCall, `/users/getUser/${payload}`);
    if (response) {
      yield put(setUserData(response['data']['body']));
    } else {
      EventBus.publish("error", error['data']['message'])
    }
  } catch (e) { yield put({ type: "IS_LOGGED_IN", payload: false }); }
};

// function* getLikedNFTs({ payload }) {
//   console.log("************** fav response");
//   // const { error, response } = yield call(getCall, `/nft/myFavsNft/${payload['userId']}`);
//   const { error, response } = yield call(getCall, `/nft/myFavsNft`);
//   console.log("************** fav response", response);
//   if (error) EventBus.publish("error", error["response"]["data"]["message"]);
//   else yield put(setLikedNFTs(response['data']['body']));
// };

function* actionWatcher() {
  yield takeEvery('LOGIN', login);
  yield takeEvery('UPDATE_USER_PROFILE', updateUserProfile);
  yield takeEvery('GET_USER_DATA', getUserData);
  // yield takeEvery('GET_LIKED_NFTS', getLikedNFTs);
}

export default function* rootSaga() {
  yield all([actionWatcher()]);
}

function postCall({ path, payload }) {
  return axios
    .post(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}


function putCallWithFile({ path, payload }) {
  const formData = new FormData();
  formData.append('avatar', payload.file);
  formData.append('bio', payload.bio);
  formData.append('email', payload.email);
  formData.append('name', payload.name);
  formData.append('portfolio', payload.portfolio);
  formData.append('publicaddress', payload.publicaddress);
  formData.append('twittername', payload.twittername);
  formData.append('url', payload.url);
  formData.append('imageUrl', payload.imageUrl);
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };
  return axios
    .put(path, formData, config)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}

function getCall(path) {
  return axios
    .get(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}

function deleteCall(path) {
  return axios
    .delete(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}

function putCall({ path, payload }) {
  return axios
    .put(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}
