export const getCategoryNFT = (data) => ({
  type: 'GET_CATEGORY_NFT',
  payload: data,
});

export const setCategoryNFT = (data) => ({
  type: 'SET_CATEGORY_NFT',
  payload: data,
});

export const getSingleNFT = (data) => ({
  type: 'GET_SINGLE_NFT',
  payload: data,
});

export const setSingleNFT = (data) => ({
  type: 'SET_SINGLE_NFT',
  payload: data,
});

export const toggleHeart = (data) => ({
  type: 'TOGGLE_HEART',
  payload: data,
});

export const setToggleHeart = (data) => ({
  type: 'GET_ISLIKED',
  payload: data,
})

export const setProfile = (data) => ({
  type: 'SET_USER_PROFILE',
  payload: data,
});

export const CreateNFT = (data) => ({
  type: 'CREATE_NFT',
  payload: data,
});

export const setLoader = (data) => ({
  type: 'SET_LOADER',
  payload: data,
});

export const SetToken = (data) => ({
  type: 'SET_TOKEN',
  payload: data,
});

export const setOrderCreate = (data) => ({
  type: 'CREATE_ORDER_API',
  payload: data,
});

/* Get Collection Data */
export const getCollectionData = (data) => ({
  type: 'GET_COLLECTION_DATA',
  payload: data,
});

/* All Collections */
export const setCollectionData = (data) => ({
  type: 'SET_COLLECTION_DATA',
  payload: data,
});

export const createCollection = (data) => ({
  type: 'CREATE_COLLECTION',
  payload: data,
});

/*  */
export const selectCollection = (data) => ({
  type: 'SELECT_COLLECTION',
  payload: data,
});

/**  Activity andExplore Actions */

export const setHotBids = (data) => ({
  type: 'SET_HOT_BIDS_DATA',
  payload: data
})


export const getCollectionName = (data) => ({
  type: 'GET_COLLECTION_NAME',
  payload: data
})


export const setCollectionName = (data) => ({
  type: 'SET_COLLECTION_NAME',
  payload: data
})

export const getSingleCollection = (data) =>({
  type: 'GET_SIGNLE_COLLECTION',
  payload: data
})

export const setSingleCollection = (data) => ({
  type: 'SET_SINGLE_COLLECTION',
  payload: data
})

export const getMyCollections = (data) => ({
  type: 'GET_MY_COLLECTIONS',
  payload: data
})


export const setMyCollections = (data) => ({
  type: 'SET_MY_COLLECTIONS',
  payload: data
})


export const getBasicCollectionDetails = (data) => ({
  type: 'GET_BASIC_COLLECTION_DETAILS',
  payload: data
});


export const setBasicCollectionDetails = (data) => ({
  type: 'SET_BASIC_COLLECTION_DETAILS',
  payload: data
});

export const getCategories = () => ({
  type: 'GET_CATEGORIES',
});

export const setCategories = (data) => ({
  type: 'SET_CATEGORIES',
  payload: data,
});

/* ============= PROFILE =========== */

export const getUserCollectedNFTs = (data) => ({
  type: 'GET_USER_COLLECTED_NFTS',
  payload: data,
});

export const setUserCollectedNFTs = (data) => ({
  type: 'SET_USER_COLLECTED_NFTS',
  payload: data,
});

export const getUserCreatedNFT = (data) => ({
  type: 'GET_USER_CREATED_NFT',
  payload: data,
});

export const setUserCreatedNFT = (data) => ({
  type: 'SET_USER_CREATED_NFT',
  payload: data,
});

export const getUserFavNFTs = (data) => ({
  type: 'GET_USER_FAV_NFTS',
  payload: data,
});

export const setUserFavNFTs = (data) => ({
  type: 'SET_USER_FAV_NFTS',
  payload: data,
});


export const getUserActivity = (data) => ({
  type: 'GET_USER_ACTIVITY',
  payload: data,
});

export const setUserActivity = (data) => ({
  type: 'SET_USER_ACTIVITY',
  payload: data,
});

/**  Activity andExplore Actions */

export const getNFTData = (data) => ({
  type: 'GET_NFT_DATA',
  payload: data,
});

export const setNFTData = (data) => ({
  type: 'SET_NFT_DATA',
  payload: data,
});

export const getNFTDataMarketplace = (data) => ({
  type: 'GET_NFT_DATA_MARKETPLACE',
  payload: data,
});

export const setNFTDataMarketplace = (data) => ({
  type: 'SET_NFT_DATA_MARKETPLACE',
  payload: data,
});

export const getAllCollections = (data) => ({
  type: 'GET_ALL_COLLECTIONS',
  payload: data,
});

export const setAllCollections = (data) => ({
  type: 'SET_ALL_COLLECTIONS',
  payload: data,
});

export const getLikedNfts = (data) => ({
  type: 'GET_LIKED_NFTS',
  payload: data,
});

export const setLikedNfts = (data) => ({
  type: 'SET_LIKED_NFTS',
  payload: data,
});