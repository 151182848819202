export const activity = [
    {
        image: "recent-one.png",
        name: "Fully Automated",
        icon: "images-people.png",
        desc: "@wrongbedroom  accepted an offer for about $2,570 on Into Eye from @rootsieman 2 HOUR AGO"
    },
    {
        image: "recent-two.png",
        name: "Fully Automated",
        icon: "images-people.png",
        desc: "@wrongbedroom  accepted an offer for about $2,570 on Into Eye from @rootsieman 2 HOUR AGO"
    },
    {
        image: "recent-three.png",
        name: "Fully Automated",
        icon: "images-people.png",
        desc: "@wrongbedroom  accepted an offer for about $2,570 on Into Eye from @rootsieman 2 HOUR AGO"
    }
]

export const sliderone = [
    {
        image: "GMetFCNToY.png",
        name: "Trending collections",
        token_watchlist: "watchlist.eth",
        bid: "Minimum bid 1/1",
        price: "0.5 wETH",
        heart: "Heart copy 6.png",
        number: "976",
    },
    {
        image: "lwAAHO1kd9.png",
        name: "Trending collections",
        token_watchlist: "watchlist.eth",
        bid: "Minimum bid 1/1",
        price: "0.5 wETH",
        heart: "Heart copy 6.png",
        number: "976",
    },
    {
        image: "top-trending.png",
        name: "Trending collections",
        token_watchlist: "watchlist.eth",
        bid: "Minimum bid 1/1",
        price: "0.5 wETH",
        heart: "Heart copy 6.png",
        number: "976",
    },
    {
        image: "butterfly-soul_59145-4.png",
        name: "Trending collections",
        token_watchlist: "watchlist.eth",
        bid: "Minimum bid 1/1",
        price: "0.5 wETH",
        heart: "Heart copy 6.png",
        number: "976",
    },
    {
        image: "GMetFCNToY.png",
        name: "Trending collections",
        token_watchlist: "watchlist.eth",
        bid: "Minimum bid 1/1",
        price: "0.5 wETH",
        heart: "Heart copy 6.png",
        number: "976",
    },
    {
        image: "lwAAHO1kd9.png",
        name: "Trending collections",
        token_watchlist: "watchlist.eth",
        bid: "Minimum bid 1/1",
        price: "0.5 wETH",
        heart: "Heart copy 6.png",
        number: "976",
    },
    {
        image: "top-trending.png",
        name: "Trending collections",
        token_watchlist: "watchlist.eth",
        bid: "Minimum bid 1/1",
        price: "0.5 wETH",
        heart: "Heart copy 6.png",
        number: "976",
    },
    {
        image: "butterfly-soul_59145-4.png",
        name: "Trending collections",
        token_watchlist: "watchlist.eth",
        bid: "Minimum bid 1/1",
        price: "0.5 wETH",
        heart: "Heart copy 6.png",
        number: "976",
    },
    {
        image: "GMetFCNToY.png",
        name: "Trending collections",
        token_watchlist: "watchlist.eth",
        bid: "Minimum bid 1/1",
        price: "0.5 wETH",
        heart: "Heart copy 6.png",
        number: "976",
    },
    {
        image: "lwAAHO1kd9.png",
        name: "Trending collections",
        token_watchlist: "watchlist.eth",
        bid: "Minimum bid 1/1",
        price: "0.5 wETH",
        heart: "Heart copy 6.png",
        number: "976",
    },
    {
        image: "top-trending.png",
        name: "Trending collections",
        token_watchlist: "watchlist.eth",
        bid: "Minimum bid 1/1",
        price: "0.5 wETH",
        heart: "Heart copy 6.png",
        number: "976",
    },
    {
        image: "butterfly-soul_59145-4.png",
        name: "Trending collections",
        token_watchlist: "watchlist.eth",
        bid: "Minimum bid 1/1",
        price: "0.5 wETH",
        heart: "Heart copy 6.png",
        number: "976",
    }
]


export const slidertwo = [

    {
        image: "lwAAHO1kd9.png",
        name: "Trending collections",
        token_watchlist: "watchlist.eth",
        bid: "Minimum bid 1/1",
        price: "0.5 wETH",
        heart: "Heart copy 6.png",
        number: "976",
    },
    {
        image: "top-trending.png",
        name: "Trending collections",
        token_watchlist: "watchlist.eth",
        bid: "Minimum bid 1/1",
        price: "0.5 wETH",
        heart: "Heart copy 6.png",
        number: "976",
    },
    {
        image: "butterfly-soul_59145-4.png",
        name: "Trending collections",
        token_watchlist: "watchlist.eth",
        bid: "Minimum bid 1/1",
        price: "0.5 wETH",
        heart: "Heart copy 6.png",
        number: "976",
    },
    {
        image: "7640fcc8f0cc3f559bf01152805ab018 copy.png",
        name: "Trending collections",
        token_watchlist: "watchlist.eth",
        bid: "Minimum bid 1/1",
        price: "0.5 wETH",
        heart: "Heart copy 6.png",
        number: "976",
    },
    {
        image: "lwAAHO1kd9.png",
        name: "Trending collections",
        token_watchlist: "watchlist.eth",
        bid: "Minimum bid 1/1",
        price: "0.5 wETH",
        heart: "Heart copy 6.png",
        number: "976",
    },
    {
        image: "top-trending.png",
        name: "Trending collections",
        token_watchlist: "watchlist.eth",
        bid: "Minimum bid 1/1",
        price: "0.5 wETH",
        heart: "Heart copy 6.png",
        number: "976",
    },
    {
        image: "butterfly-soul_59145-4.png",
        name: "Trending collections",
        token_watchlist: "watchlist.eth",
        bid: "Minimum bid 1/1",
        price: "0.5 wETH",
        heart: "Heart copy 6.png",
        number: "976",
    },
    {
        image: "7640fcc8f0cc3f559bf01152805ab018 copy.png",
        name: "Trending collections",
        token_watchlist: "watchlist.eth",
        bid: "Minimum bid 1/1",
        price: "0.5 wETH",
        heart: "Heart copy 6.png",
        number: "976",
    },
    {
        image: "lwAAHO1kd9.png",
        name: "Trending collections",
        token_watchlist: "watchlist.eth",
        bid: "Minimum bid 1/1",
        price: "0.5 wETH",
        heart: "Heart copy 6.png",
        number: "976",
    },
    {
        image: "top-trending.png",
        name: "Trending collections",
        token_watchlist: "watchlist.eth",
        bid: "Minimum bid 1/1",
        price: "0.5 wETH",
        heart: "Heart copy 6.png",
        number: "976",
    },
    {
        image: "butterfly-soul_59145-4.png",
        name: "Trending collections",
        token_watchlist: "watchlist.eth",
        bid: "Minimum bid 1/1",
        price: "0.5 wETH",
        heart: "Heart copy 6.png",
        number: "976",
    },
    {
        image: "7640fcc8f0cc3f559bf01152805ab018 copy.png",
        name: "Trending collections",
        token_watchlist: "watchlist.eth",
        bid: "Minimum bid 1/1",
        price: "0.5 wETH",
        heart: "Heart copy 6.png",
        number: "976",
    }
]