import EventBus from 'eventing-bus';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { InputLabel, MenuItem, FormControl, Select } from '@material-ui/core';

import './index.css';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';

import { getUserData } from '../../store/actions/Auth';
import { CreateIPFS } from '../../store/helper';
import { decodeIPFS } from '../../store/helper'
import { createCollection, getCategories, getAllCollections, } from '../../store/actions/NFT';
import { TokenABI, TokenAddress, Marketplace, Tokenbytecode, MarketplaceAddress, web3, web3Obj } from '../../store/contract';
import { Link } from 'react-router-dom';

class CreateSingle extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    props.getCategories();
    props.getAllCollections();
  };

  handleUploadLogo = async (event) => {
    try {
      let file = event.target.files[0];
      let bytes = file.size;
      let name = file.name;
      if (bytes > 1e+8) return EventBus.publish('error', 'Error: Please Uplaod lessthen 100 MB');
      let uri = await CreateIPFS(file);
      this.setState({ uploadLogo: uri, logoName: name });
      EventBus.publish('success', 'Logo Uploaded');
    } catch (error) {
      EventBus.publish('error', 'Error uploading file: ', error);
    }
  }

  handleUploadBanner = async (event) => {
    try {
      let file = event.target.files[0];
      let bytes = file.size;
      let name = file.name;
      if (bytes > 1e+8) return EventBus.publish('error', 'Error: Please Uplaod lessthen 100 MB');
      let uri = await CreateIPFS(file);
      this.setState({ uploadBanner: uri, bannerName: name });
      EventBus.publish('success', 'Banner Uploaded');
    } catch (error) {
      EventBus.publish('error', 'Error uploading file: ', error);
    }
  }

  componentDidMount() {
    this.setState(
      this.props.getAllCollections()
    )
  }

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

  componentWillReceiveProps({ collectionsMarketplace }) {
    this.setState({ collectionsMarketplace });
  };

  createMarketCollections = async () => {
    let { createCollection } = this.props;
    let { name, symbol, url, selectedCategory, description, website, discord, instagram, medium, telegram, uploadBanner, uploadLogo } = this.state;


    let checkCollections = this.props.collectionsMarketplace.find(i => i.collectionName == name);
    if (checkCollections) {
      EventBus.publish('info', `Collection name already existed`);
      return;
    }

    if (uploadBanner == '') {
      EventBus.publish('info', `Please Select Banner`);
      return;
    };
    if (uploadLogo == '') {
      EventBus.publish('info', `Please Select Logo`);
      return;
    };
    if (name == '') {
      EventBus.publish('info', `Please Enter Display Name`);
      return;
    };
    if (selectedCategory == '') {
      EventBus.publish('info', `Please Enter the Category`);
      return;
    };
    if (symbol == '') {
      EventBus.publish('info', `Please Enter the Symbol`);
      return;
    };



    try {
      

      let contract = new web3.eth.Contract(TokenABI);
      let from = (await web3.currentProvider.enable())[0];
      let deploy = await contract.deploy({ data: Tokenbytecode, arguments: [name, symbol] });

      await deploy.send({ from })
        .on('transactionHash', hash => console.log(`************** Collection creation hash = ${hash}`))
        .on('receipt', async receipt => {

          const obj = JSON.stringify({
            name,
            symbol,
            shortUrl: url,
            owner: from,
            description,
            collectionAddress: receipt['contractAddress']
          });
          const uri = await CreateIPFS(obj);
          let data = {
            uri,
            collectionName: name,
            category: selectedCategory,
            website,
            discord,
            instagram, medium, telegram, uploadBanner, uploadLogo,
            contractType: 721,
            collectionAddress: receipt['contractAddress'],
            transactionHash: receipt['transactionHash'],
          };
          createCollection(data);
          this.setState({
            name: '', symbol: '', url: '', selectedCategory: '', description: '', website: '', discord: '',
            instagram: '', medium: '', telegram: '', uploadBanner: '', uploadLogo: '', bannerName: '', logoName: ''
          });
        }).on('error', error => {
          EventBus.publish('error', 'Unable to Create Collection');
        });
    } catch (error) {
      EventBus.publish('error', 'Unable to Create Collection');
    }
  }


  render() {
    let { setCategories } = this.props;
    let { name, url, selectedCategory, description, website, discord, instagram, medium, telegram, symbol, bannerName, logoName } = this.state;
    console.log("***********************collection", this.props.collectionsMarketplace);
    return (
      <div className='singlepage'>
        <Navbar />
        {/* Product Section */}
        <section className='create-product' style={{ backgroundImage: `url(${require("../../static/images/landing/create-bg.png")})` }}>
          <div className='auto-container'>
            <div className='row'>
              <div className='create-block col-lg-12 col-md-12'>
                <div className='create-product-inner'>
                  {/* <a href='#' className='back-bredcome'> <i className='fa fa-arrow-left'></i> Create a Collection</a> */}
                  <div className='create-area'>
                    <div className='sec-title'>
                      <h2>Create a Collection</h2>
                      <p>Choose "Single" for one of a kind or "Multiple" if you want to sell one collectible multiple times</p>
                    </div>
                    <div className='upload-area'>
                      <h3>Logo image <span>*</span></h3>
                      <p>This image will also be used for navigation. 350 x 350 recommended.</p>
                      <div className='upload-btn'>
                        <h3>{logoName ? logoName.slice(0, 15) : 'Drag and Drop file here'}</h3>
                        <p>Files Supported: PNG, JPEG or JPG</p>
                        <input required type='file' className='custom-file-inputt' name='logo' onChange={this.handleUploadLogo} accept="image/png, image/jpg, image/jpeg, image/gif" id='contained-button-file' />
                        <i className='icon'><img src={require("../../static/images/landing/upload-icon.png")} alt="" /></i>
                        <p>Maximum size: 100 MB</p>
                      </div>
                    </div>

                    <div className='upload-area'>
                      <h3>Banner image</h3>
                      <p>This image will appear at the top of your collection page. Avoid including too much text in this banner image, as the dimensions change on different devices. 1400 x 400 recommended.</p>
                      <div className='upload-btn'>
                        <h3>{bannerName ? bannerName.slice(0, 15) : 'Drag and Drop file here'}</h3>
                        <p>Files Supported: PNG, JPEG or JPG</p>
                        <input required type='file' className='custom-file-inputt' name='banner' onChange={this.handleUploadBanner} accept="image/png, image/jpg, image/jpeg, image/gif" id='contained-button-file' />
                        <i className='icon'><img src={require("../../static/images/landing/upload-icon.png")} alt="" /></i>
                        <p>Maximum size: 100 MB</p>
                      </div>
                    </div>

                    <ValidatorForm className="create-nft-form row">
                      <h3>Name<span>*</span></h3>
                      <div className="price-inut mb-3 col-lg-7 col-md-12">
                        <TextValidator
                          fullWidth
                          type="text"
                          name="name"
                          value={name}
                          id="standard-full-width"
                          placeholder="Example: Sea wonders"
                          className="create-nft-field"
                          label={<label>Example: Sea wonders</label>}
                          onChange={this.handleChange}
                          validators={["required"]}
                          errorMessages={["Please enter name"]}
                        />
                      </div>

                      <h3>Symbol<span>*</span></h3>
                      <div className="price-inut mb-3 col-lg-7 col-md-12">
                        <TextValidator
                          fullWidth
                          type="text"
                          name="symbol"
                          value={symbol}
                          id="standard-full-width"
                          placeholder="Example: SeaW"
                          className="create-nft-field"
                          label={<label>Example: SeaW</label>}
                          onChange={this.handleChange}
                          validators={["required"]}
                          errorMessages={["Please enter symbol"]}
                        />
                      </div>

                      <h3>URL</h3>
                      <p>Customize your URL on Marketplace. Must only contain lowercase letters,numbers, and hyphens.</p>
                      <div className="price-inut mb-3 col-lg-7 col-md-12">
                        <TextValidator
                          fullWidth
                          type="text"
                          name="url"
                          value={url}
                          id="standard-full-width"
                          placeholder="https://marketplace.com/collection/sea-wonders"
                          className="create-nft-field"
                          label={<label>https://marketplace.com....</label>}
                          onChange={this.handleChange}
                        />
                      </div>

                      <h3>Description</h3>
                      <p><span className='theme-color'>Markdown</span> syntax is supported. 0 of 1000 characters used.</p>
                      <div className="price-inut mb-3 col-lg-7 col-md-12">
                        <TextValidator
                          fullWidth
                          type="textarea"
                          name="description"
                          value={description}
                          id="standard-full-width"
                          placeholder="Description"
                          className="create-nft-field"
                          label={<label>Description</label>}
                          onChange={this.handleChange}
                        />
                      </div>

                      {/* <h3>Number of copies</h3>
                      <div className="price-inut mb-3 col-lg-7 col-md-12">
                        <TextValidator
                          fullWidth
                          type="text"
                          name="Number"
                          value=""
                          id="standard-full-width"
                          placeholder="Number of copies"
                          className="create-nft-field"
                          label={<label>Number of copies</label>}
                        // onChange={this.handlePriceBid}
                        // validators={["required"]}
                        // errorMessages={["Price cannot be empty"]}
                        />
                      </div> */}

                      <div className='category-area col-lg-7 col-md-12'>
                        <h3>Category</h3>
                        <p>Adding a category will help make your item discoverable on Marketplace.</p>
                        {/* <button className="btn-style-one">Add category<i className="icon fa fa-plus"></i></button> */}
                        {/* <div className="price-inut mb-3 col-lg-7 col-md-12"> */}
                        <Select
                          fullWidth
                          name="selectedCategory"
                          id="standard-full-width"
                          value={selectedCategory}
                          className="create-nft-field"
                          onChange={this.handleChange}
                          validators={["required"]}
                          errorMessages={["Select a category"]}>
                          {setCategories && setCategories.map(category => {
                            return (
                              <MenuItem value={category['category']}>{category['category']}</MenuItem>
                            )
                          })
                          }
                        </Select>
                        {/* </div> */}
                      </div>


                      <div className='links-area col-lg-7 col-md-12'>
                        <h3>Links</h3>

                        <div className="price-inut">
                          <TextValidator
                            fullWidth
                            type="text"
                            name="website"
                            value={website}
                            id="standard-full-width"
                            placeholder="Your Website"
                            className="create-nft-field"
                            label={<label>Your Website</label>}
                            onChange={this.handleChange}
                          />
                          <i className='icon'><img src={require("../../static/images/landing/landingnft/profile/web-form.png")} alt="" /></i>
                        </div>

                        <div className="price-inut">
                          <TextValidator
                            fullWidth
                            type="text"
                            name="discord"
                            value={discord}
                            id="standard-full-width"
                            placeholder="Your Discord id"
                            className="create-nft-field"
                            label={<label>Your Discord id</label>}
                            onChange={this.handleChange}

                          />
                          <i className='icon'><img src={require("../../static/images/landing/landingnft/profile/discord-form.png")} alt="" /></i>
                        </div>

                        <div className="price-inut">
                          <TextValidator
                            fullWidth
                            type="text"
                            name="instagram"
                            value={instagram}
                            id="standard-full-width"
                            placeholder="Your Instagram handle"
                            className="create-nft-field"
                            label={<label>Your Instagram handle</label>}
                            onChange={this.handleChange}

                          />
                          <i className='icon'><img src={require("../../static/images/landing/landingnft/profile/instagram-form.png")} alt="" /></i>
                        </div>

                        <div className="price-inut">
                          <TextValidator
                            fullWidth
                            type="text"
                            name="medium"
                            value={medium}
                            id="standard-full-width"
                            placeholder="Your Medium handle"
                            className="create-nft-field"
                            label={<label>Your Medium handle</label>}
                            onChange={this.handleChange}

                          />
                          <i className='icon'><img src={require("../../static/images/landing/landingnft/profile/meduim-form.png")} alt="" /></i>
                        </div>

                        <div className="price-inut">
                          <TextValidator
                            fullWidth
                            type="text"
                            name="telegram"
                            value={telegram}
                            id="standard-full-width"
                            placeholder="Your Telegram id"
                            className="create-nft-field"
                            label={<label>Your Telegram id</label>}
                            onChange={this.handleChange}

                          />
                          <i className='icon'><img src={require("../../static/images/landing/landingnft/profile/telegram-from.png")} alt="" /></i>
                        </div>
                      </div>
                    </ValidatorForm>

                    <div className='group-from row'>
                      <button className='btn-style-one' onClick={this.createMarketCollections}>Create<i className='icon fa fa-plus'></i></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />

      </div>
    );
  }

}

const mapStateToProps = ({ NFT }) => {
  let { setCategories, selectedCollection, collectionsMarketplace } = NFT;
  return { setCategories, selectedCollection, collectionsMarketplace }
};
const mapDispatchToProps = { getUserData, createCollection, getCategories, getAllCollections };

export default connect(mapStateToProps, mapDispatchToProps)(CreateSingle);