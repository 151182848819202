import EventBus from "eventing-bus";
import { connect } from "react-redux";
import React, { Component } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { TextField } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Send, CheckCircle, Info, Error, Done, Facebook, Twitter, Instagram, LinkedIn, LiveTvRounded } from '@material-ui/icons';

import './index.css';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { decodeIPFS } from '../../store/helper';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { getMyCollections, getBasicCollectionDetails, getSingleCollection } from '../../store/actions/NFT'
import { Redirect } from 'react-router'


class Collection extends Component {
  constructor(props) {
    super(props);



    this.state = {
      isLoadingBasicItems: true,
    };
  };



  componentDidMount() {
    this.props.getSingleCollection(this.props.location.state.collecionName);
    //this.props.getMyCollections({ collectionName: this.props.match.params.collection });

  }

  async componentWillReceiveProps({ singleCollection, setBasicCollectionDetails }) {
    if (singleCollection['collectionAddress']) {
      this.props.getBasicCollectionDetails(singleCollection['collectionAddress']);
    }
    if (Object.keys(setBasicCollectionDetails).length !== 0) { this.setState({ isLoadingBasicItems: false }) }
  }

  copy = () => EventBus.publish("success", 'Wallet Address Copied');

  render() {

    let { address, setBasicCollectionDetails, getBasicCollectionDetails, singleCollection } = this.props
    let { isLoadingBasicItems } = this.state
    console.log("********************** collection info", singleCollection,this.props.location.state.collecionName)

    if (!address) {
      return <Redirect to="/" />
    }

    return (
      <div className='profile-nft'>

        <Navbar />
        {/* Haeder Section */}
        {singleCollection &&
          <>
            <section className="header-section" style={{ backgroundImage: `url(${singleCollection['uploadBanner']})` }}>
            </section>

            <div className="first-section collection-sec" style={{ backgroundImage: `url(${require("../../static/images/landing/landingnft/profile/profile-bg.png")})` }}>
              <div className="auto-container">
                <div className="tocaya">
                  <div className="images-mid text-center">
                    <img src={singleCollection['uploadLogo']} className="image-avatar" alt="" />
                    <h3>{singleCollection['collectionName']}</h3>

                    <p>Created by <Link to="#">{singleCollection['ownerAddress'] === address ? 'You' : singleCollection['ownerAddress']}</Link></p>
                    <div className="profile-setting-area">
                      <button type="button" className="btn-style-one">Add to watchlist <i className='icon fa fa-plus'></i></button>
                      {singleCollection['website'] && <Link className="profile-area" to={singleCollection['website']}><img src={require("../../static/images/landing/collection/web-icon.png")} alt="" /></Link>}
                      {singleCollection['discord'] && <Link className="profile-area" to={singleCollection['discord']}><img src={require("../../static/images/landing/collection/discord.png")} alt="" /></Link>}
                      {singleCollection['instagram'] && <Link className="profile-area" to={singleCollection['instagram']}><img src={require("../../static/images/landing/collection/instagram.png")} alt="" /></Link>}
                      {singleCollection['medium'] && <Link className="profile-area" to={singleCollection['medium']}><img src={require("../../static/images/landing/collection/dots.png")} alt="" /></Link>}
                    </div>
                  </div>
                </div>
              </div>
              <div className="detail-sec">
                <div className="auto-container">
                  <div className="row">
                    <div className="col-12">
                      <div className="detail-inner text-center">
                        <ul className="item-area">
                          <li>{isLoadingBasicItems ? <span>Loading</span> : <span>{setBasicCollectionDetails['tokenIds']} <span>items</span></span>} </li>
                          <li>{isLoadingBasicItems ? <span>Loading</span> : <span> {setBasicCollectionDetails['TotalOwners']}<span>owners</span></span>}</li>
                          <li>{isLoadingBasicItems ? <span>Loading</span> : <span> {setBasicCollectionDetails['calculateFlourPrice']} <span>floor price</span></span>}</li>
                          <li>{isLoadingBasicItems ? <span>Loading</span> : <span> {setBasicCollectionDetails['volumeTrade']} <span>volume traded</span></span>}</li>
                        </ul>
                        <p>{singleCollection['description'] && singleCollection['description']}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="collection-box">
                <div className="auto-container">
                  <div className="row">
                    <div className="buttons-block col-lg-3 col-md-12">
                      <div className="buttons-inner">
                        <div className="filers-area">
                          <h5>Filter</h5>
                        </div>
                        <div id="accordion">
                          <div class="card">
                            <div class="card-header" id="headingOne">
                              <h5 class="mb-0">
                                <button class="red btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                  Status
                                </button>
                              </h5>
                            </div>
                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                              <div class="card-body">
                                <ul className="filter-list">
                                  <li className="listings">
                                    <button>Buy Now</button>
                                  </li>
                                  <li className="listings">
                                    <button>On Auction</button>
                                  </li>
                                  <li className="listings">
                                    <button>New</button>
                                  </li>
                                  <li className="listings">
                                    <button>Has Offers</button>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div class="card">
                            <div class="card-header" id="headingTwo">
                              <h5 class="mb-0">
                                <button class="white btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                  Price
                                </button>
                              </h5>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                              <div class="card-body">
                                <div className="fitter-search">
                                  <div className="group-form">
                                    <TextField
                                      fullWidth
                                      // value={name}
                                      label="$ United States Dollar..."
                                      margin="normal"
                                      id="standard-name"
                                    // onChange={this.handleChange('name')}
                                    />
                                    <i className="icon"><img src={require("../../static/images/landing/landingnft/category/down-cat.png")} alt="" /></i>
                                  </div>

                                  <div className="group-form min-max-group">
                                    <TextField
                                      fullWidth
                                      // value={name}
                                      label="Min"
                                      margin="normal"
                                      id="standard-name"
                                    // onChange={this.handleChange('name')}
                                    />
                                    <p>to</p>
                                    <TextField
                                      fullWidth
                                      // value={name}
                                      label="Max"
                                      margin="normal"
                                      id="standard-name"
                                    // onChange={this.handleChange('name')}
                                    />
                                  </div>
                                  <button type="button" className="btn-style-one">Apply <i className='icon'><img src={require("../../static/images/landing/collection/flitter-icon.png")} alt="" /></i></button>
                                </div>

                              </div>
                            </div>
                          </div>

                          <div class="card">
                            <div class="card-header" id="headingThree">
                              <h5 class="mb-0">
                                <button class="blue btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                  Chains
                                </button>
                              </h5>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                              <div class="card-body">
                                <div className="collections-box style-two">
                                  <div className="collections-block">
                                    <div className="img-box">
                                      <img src={require("../../static/images/landing/landingnft/category/etherum.png")} alt="" />
                                    </div>
                                    <div className="text-box">
                                      <h4>Etnereum</h4>
                                    </div>
                                  </div>

                                  <div className="collections-block">
                                    <div className="img-box">
                                      <img src={require("../../static/images/landing/landingnft/category/polygen.png")} alt="" />
                                    </div>
                                    <div className="text-box">
                                      <h4>Polygon</h4>
                                    </div>
                                  </div>

                                  <div className="collections-block">
                                    <div className="img-box">
                                      <img src={require("../../static/images/landing/landingnft/category/klaytn.png")} alt="" />
                                    </div>
                                    <div className="text-box">
                                      <h4>Klaytn</h4>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>



                        </div>
                      </div>
                    </div>

                    <div className="collection-nft-box col-lg-9 col-md-12">
                      <div className="collection-nft-inner">
                        <div className="tab-box">
                          <button className="tab-btn active"><i className="icon"><img src={require("../../static/images/landing/collection/grid-icon.png")} alt="" /></i> Items</button>
                          <button className="tab-btn"><i className="icon"><img src={require("../../static/images/landing/collection/activity-icon.png")} alt="" /></i> Activity</button>
                        </div>
                        <div className="search-area row">
                          <div className="group-form search-form col-lg-4 col-md-6 col-sm-12">
                            <TextField
                              fullWidth
                              // value={name}
                              label="Search"
                              margin="normal"
                              id="standard-name"
                            // onChange={this.handleChange('name')}
                            />
                          </div>
                          <div className="group-form single-form col-lg-3 col-md-6 col-sm-12">
                            <TextField
                              fullWidth
                              // value={name}
                              label="Single items"
                              margin="normal"
                              id="standard-name"
                            // onChange={this.handleChange('name')}
                            />
                            <i className="icon"><img src={require("../../static/images/landing/landingnft/category/down-cat.png")} alt="" /></i>
                          </div>
                          <div className="group-form single-form col-lg-3 col-md-6 col-sm-12">
                            <TextField
                              fullWidth
                              // value={name}
                              label="Recently received"
                              margin="normal"
                              id="standard-name"
                            // onChange={this.handleChange('name')}
                            />
                            <i className="icon"><img src={require("../../static/images/landing/landingnft/category/down-cat.png")} alt="" /></i>
                          </div>
                          <div className="group-form button-form col-lg-2 col-md-6 col-sm-12">
                            <button className="expend-button"><img src={require("../../static/images/landing/landingnft/category/expend.png")} alt="" /></button>
                            <button className="expend-button"><img src={require("../../static/images/landing/landingnft/category/expend-2.png")} alt="" /></button>
                          </div>
                        </div>
                        <div className="nft-collection-area">
                          <h3>{singleCollection['nfts'] && singleCollection['nfts'].length} items</h3>
                          <div className="row clearfix">
                            {singleCollection['nfts'] && singleCollection['nfts'].length > 0 ?
                              singleCollection['nfts'].map(data => {
                                return (<div className="nft-box col-lg-4 col-md-6 col-sm-12">
                                  <div className="nft-box-inner">
                                    <div className="image-box">
                                      <img src={data['imageUrl']? data['imageUrl'] :require("../../static/images/landing/collection/nft-1.png")} alt="" />
                                    </div>
                                    <div className="text-box">
                                      <div className="left-area">
                                        <h5>{singleCollection.collectionName}</h5>
                                        <h4>{`${singleCollection.collectionName} #${data.tokenId}`}</h4>
                                        <Link to="#" className="heart"><img src={require("../../static/images/landing/collection/heart.png")} alt="" /></Link>
                                      </div>
                                      <div className="right-area">
                                        <p>Top bid</p>
                                        <h3><i className="icon"><img src={require("../../static/images/landing/collection/eth.png")} alt="" /></i>0.0023</h3>
                                        <h6>5 days left</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>)
                              })
                              :
                              <div className="nft-box col-lg-12 col-md-12 col-sm-12">
                                <div className="nft-box-inner">
                                  <div className="text-box text-center">
                                    <h5>No items to display</h5>
                                  </div>
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </>
        }
        <Footer />

      </div>
    );
  }

}

const mapDispatchToProps = { getMyCollections, getBasicCollectionDetails, getSingleCollection };

const mapStateToProps = ({ Auth, NFT }) => {
  let { address } = Auth;
  let { setMyCollections, setBasicCollectionDetails, singleCollection } = NFT;
  return { address, setMyCollections, setBasicCollectionDetails, singleCollection }
};


export default connect(mapStateToProps, mapDispatchToProps)(Collection);