import EventBus from 'eventing-bus';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import { HashLink } from 'react-router-hash-link';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import { web3 } from '../../store/web3';
import { networkId } from '../../store/config';
import { login, isLoggedIn, logout, toggleConnectModal, toggleSwitchModal } from '../../store/actions/Auth';

import './index.css';

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isNetwork: false,
            isMetaMask: false,
            publicAddress: localStorage.getItem('publicAddress'),
        };
    };

    connectWallet = async () => {
        if (typeof window.ethereum === 'undefined') {
            this.setState({ isMetaMask: !this.state.isMetaMask });
            return;
        }

        web3.eth.net.getId(async (err, netId) => {
            if (netId != networkId) {
                this.setState({ isNetwork: !this.state.isNetwork });
                return;
            }
            let address = (await web3.currentProvider.enable())[0];
            this.props.isLoggedIn(true);
            this.props.login({ publicAddress: address, type: 'login' });
        });
    };

    logoutWallet = () => {
        this.props.logout();
        this.setState({ isLogout: !this.state.isLogout, isPhysicalAddress: false, publicAddress: '' });
    };

    componentDidUpdate(prevProps, prevState) {
        let paddress = localStorage.getItem('publicAddress');
        if (prevProps.address !== this.props.address) {
            if (this.props.address === paddress)
                this.setState({ isPhysicalAddress: true, publicAddress: paddress });
            else this.setState({ isPhysicalAddress: false });
        }
    };

    copied = () => EventBus.publish('success', 'Wallet Address Copied');
    toggleNetworkModal = () => this.setState({ isNetwork: !this.state.isNetwork });
    toggleMetaMaskModal = () => this.setState({ isMetaMask: !this.state.isMetaMask });

    render() {
        let { address, userprofileData } = this.props;
        let { isMetaMask, isNetwork, publicAddress } = this.state;
        return (
            <div className='main-header'>
                <nav className='navbar navbar-expand-lg'>
                    <div className='auto-container'>
                        <div className='inner-container'>
                            <Link className='navbar-brand' href='#'><img src={require('../../static/images/landing/landingnft/logo.png')} alt='' /></Link>

                            <button className='navbar-toggler' type='button' data-toggle='collapse'
                                data-target='#navbarSupportedContent' aria-controls='navbarSupportedContent' aria-expanded='false'
                                aria-label='Toggle navigation'>
                                <i class='fa fa-bars ' aria-hidden='true'></i>
                            </button>
                            <div className='collapse navbar-collapse nav-links' id='navbarSupportedContent'>
                                {/* <div className='searchContainer'>
                                <i class='fa fa-search' aria-hidden='true'></i>
                                <input className='searchBox' type='search' name='search' placeholder='Search by creator, collectible or collection' />
                            </div> */}
                                <ul className='navbar-nav ml-auto'>
                                    <li className='nav-item active'>
                                        <HashLink className='nav-link pr-lg-4' smooth to='/'>Home</HashLink>
                                    </li>
                                    <li className='nav-item'>
                                        <Link className='nav-link  pr-lg-4' to='/category' >Explore</Link>
                                    </li>
                                    {/* <li className='nav-item'>
                                        <HashLink className='nav-link  pr-lg-4' smooth to=''>My items</HashLink>
                                    </li> */}
                                    <li className='nav-item'>
                                        <Link className='nav-link  pr-lg-4' to='/activity' >Activity</Link>
                                    </li>
                                    
                                    {address && <Link className='btn-style-one' to='/createSingle' >Create <i className='icon'><img src={require("../../static/images/landing/landingnft/user-avatar.png")} alt="" /></i></Link>}

                                </ul>

                                {!address
                                    ? <div className='button-head'>
                                        <button className='btn-style-one' onClick={this.connectWallet}>Connect <i className='icon'><img src={require("../../static/images/landing/landingnft/user-avatar.png")} alt="" /></i></button>
                                    </div>
                                    : <div class='dropdown main-drop show'>
                                        <a class='dropdown-toggle' href='#' role='button' id='dropdownMenuLink' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                                            <img src={userprofileData?.avatar ? userprofileData?.avatar : require('../../static/images/landing/landingnft/avtar3.png')} alt='' />
                                        </a>
                                        <div class='dropdown-menu dropdown-main-nav profile-dropdown' aria-labelledby='dropdownMenuLink'>
                                            <div className='form-modal-landing-nft'>
                                                <div className='copy-address'><p>{publicAddress?.substring(0, 10) + '.....' + publicAddress?.substring(35, publicAddress.length)} <i class='fa fa-files-o' aria-hidden='true'></i></p></div>
                                                <a href='#' className='name-area'>Set display name</a>
                                            </div>
                                            <div className='nft-balance-area'>
                                                <div className='nft-balance-box'>
                                                    <div className='nft-balance'>
                                                        <div className='nft-icon'>
                                                            <img src={userprofileData?.avatar ? userprofileData?.avatar : require('../../static/images/landing/landingnft/avtar3.png')} alt='' />
                                                        </div>
                                                        <div className='nft-text'>
                                                            <h4>NFT Balance</h4>
                                                            <p>0 NFT</p>
                                                        </div>
                                                    </div>
                                                    {/* <a className='claim-btn' href='#'>Claim</a> */}
                                                </div>
                                                <div className='nft-balance-box'>
                                                    <div className='nft-balance'>
                                                        <div className='nft-icon'>
                                                            <img src={userprofileData?.avatar ? userprofileData?.avatar : require('../../static/images/landing/landingnft/profile/eth.png')} alt='' />
                                                        </div>
                                                        <div className='nft-text'>
                                                            <h4>Balance</h4>
                                                            <p>0 ETH</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className='nft-balance-box'>
                                                    <div className='nft-balance'>
                                                        <div className='nft-icon'>
                                                            <img src={userprofileData?.avatar ? userprofileData?.avatar : require('../../static/images/landing/landingnft/profile/eth-2.png')} alt='' />
                                                        </div>
                                                        <div className='nft-text'>
                                                            <h4>Bidding Balance</h4>
                                                            <p>0 wETH</p>
                                                        </div>
                                                    </div>
                                                    <a className='transfer-btn' href='#'> <i className='icon fa fa-exchange'></i> </a>
                                                </div> */}
                                                {/* <div className='visabutton'>
                                                    <a className='vais-btn' href='#'>Add Funds with <span className='icon'><img src={require('../../static/images/landing/landingnft/profile/visa-card.png')} alt='' /></span></a>
                                                </div> */}
                                            </div>
                                            <div className='nft-user-link'>
                                                <ul>
                                                    <li><Link className='nav-link' to='/myCollection' >My Collections</Link></li>
                                                    <li><Link className='nav-link' to='/editprofile' >Edit profile</Link></li>
                                                    <li><Link className='nav-link  pr-lg-4' to={`/profile/${this.state.publicAddress}`} >User Profile</Link></li>
                                                    {/* <li><Link className='nav-link' to='' >Manage funds</Link></li>
                                                    <li><Link className='nav-link' to='' >Autoplay</Link></li> */}
                                                    {/* <li><Link className='nav-link' to='/collection/my-collections-2' >My Collections</Link></li> */}
                                                    <li><Link onClick={this.logoutWallet} className='nav-link' to='' >Disconnect</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {/* ------------------METAMASK MODAL----------------- */}
                                <Modal isOpen={isMetaMask} toggle={this.toggleMetaMaskModal} className='main-modal metamask-modal'>
                                    <ModalHeader toggle={this.toggleMetaMaskModal}>
                                        <div className='modal-logo'>
                                            <img src={require('../../static/images/landing/logo.png')} alt='' />
                                        </div>
                                    </ModalHeader>
                                    <ModalBody className='modal-body' >
                                        <div className='container modal-upper-pad'>
                                            <div className='text-center form-modal-landing-nft'>
                                                <div className='message-text'><p>Please Install Metamask!!!</p></div>
                                            </div>

                                        </div>
                                    </ModalBody>
                                </Modal>

                                {/* ------------------NETWORK MODAL----------------- */}
                                <Modal isOpen={isNetwork} toggle={this.toggleNetworkModal} className='main-modal metamask-modal'>
                                    <ModalHeader toggle={this.toggleNetworkModal}>
                                        <div className='modal-logo'>
                                            <img src={require('../../static/images/landing/logo.png')} alt='' />
                                        </div>
                                    </ModalHeader>
                                    <ModalBody className='modal-body' >
                                        <div className='container modal-upper-pad'>
                                            <div className='text-center form-modal-landing-nft'>
                                                <div className='message-text'><p>Please Change Your Network to Rinkeby Test Network</p></div>
                                            </div>
                                        </div>
                                    </ModalBody>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        );
    }
}

const mapDispatchToProps = { login, isLoggedIn, logout, toggleConnectModal, toggleSwitchModal };

const mapStateToProps = ({ Auth }) => {
    let { isLogin, isSignup, isSignupInfo, address, isPolicyModal, userprofileData } = Auth;
    return { isLogin, isSignup, isSignupInfo, address, isPolicyModal, userprofileData }
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);