import axios from 'axios';
import EventBus from 'eventing-bus';
import { all, takeEvery, call, put } from 'redux-saga/effects';
import { setTopCategories, setCategoryNFT, setCategories, setBasicCollectionDetails, setTypesNFT,setSingleCollection , setMyCollections, setProfileFromApi, setSingleNFT, setToggleHeart, setHotBids, setCollectionData, setUserCollectedNFTs, setUserCreatedNFT, setUserFavNFTs, setUserActivity, setNFTData, setNFTDataMarketplace, setLikedNfts, setAllCollections } from '../actions/NFT';


function* createNFT({ payload }) {
  const { error, response } = yield call(postCall, { path: `/nft/postnft`, payload: payload });
  if (response) EventBus.publish("success", response['data']['message'])
  else EventBus.publish("error", error['data']['message'])
};

function* getCategoryNFT({ payload }) {
  if (payload == 'all') {
    const { error, response } = yield call(getCall, `/nft/getall/`);
    if (error) EventBus.publish("error", error["response"]["data"]["message"]);
    else if (response) yield put(setCategoryNFT(response['data']['body']));
  } else {
    const { error, response } = yield call(getCall, `/nft/getNftbyCate/${payload}`);
    if (error) EventBus.publish("error", error["response"]["data"]["message"]);
    else if (response) yield put(setCategoryNFT(response['data']['body']));
  }
};

function* getSingleNFT({ payload }) {
  const { error, response } = yield call(getCall, `nft/getSingle/${payload}`);
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else if (response) yield put(setSingleNFT(response['data']['body']));
};

function* toggleHeart({ payload }) {
  const { error, response } = yield call(putCall, { path: `/nft/likeNft`, payload });
  if (response) {
    yield put(setToggleHeart(response['data']['body']));
    EventBus.publish("success", response['data']['message'])
  }
  else EventBus.publish("error", error['data']['message'])
};

function* createCollection({ payload }) {
  const { error, response } = yield call(postCall, { path: `/nft/collectionCreate`, payload: payload });
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else EventBus.publish("success", 'Collection Created Successfully');
};

function* getCollectionData({ payload }) {
  const { error, response } = yield call(postCall, { path: `/nft/collectionGet`, payload: payload });
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else yield put(setCollectionData(response['data']['body']));
};

function* getCollectionsName({ payload }) {
  const { error, response } = yield call(postCall, { path: `/nft/getCollectionName`, payload: payload });
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else yield put(setCollectionData(response['data']['body']));
};

function* getSingleCollection({ payload }) {
  const { error, response } = yield call(getCall, `/nft/getSingleCollections/${payload}`);
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else yield put(setSingleCollection(response['data']['body']));
};


function* getMyCollections({ payload }) {
  const { error, response } = yield call(getCall, `/nft/getMyCollections/${payload}`);
  console.log("********************res",response);
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else yield put(setMyCollections(response['data']['body']));
};


function* getCategories() {
  const { error, response } = yield call(getCall, `/nft/getAllCategories`);
  console.log("****************** response", response);
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else yield put(setCategories(response['data']['body']));
};

function* getBasicCollectionDetails({ payload }) {
  const { error, response } = yield call(getCall, `/nft/getBasicCollectionDetails/${payload}`);
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else yield put(setBasicCollectionDetails(response['data']['body']));
}

function* getUserFavNFTs({ payload }) {
  const { error, response } = yield call(getCall, `/nft/UserFavoritedNftList/${payload}`);
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else yield put(setUserFavNFTs(response['data']['body']));
};

function* getUserCollectedNFTs({ payload }) {
  const { error, response } = yield call(getCall, `/nft/getUserCollectedNFTs/${payload}`);
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else yield put(setUserCollectedNFTs(response['data']['body']['assets']));
};

function* getUserCreatedNFT({ payload }) {
  const { error, response } = yield call(getCall, `/nft/createdNFT/${payload}`);
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else yield put(setUserCreatedNFT(response['data']['body']));
};

function* getUserActivity({ payload }) {
  const { error, response } = yield call(getCall, `/nft/userActivity/${payload['address']}/${payload['eventType']}`);
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else yield put(setUserActivity(response['data']['body']));
};

////////////************************ NFT DATA API ************************////////////

function* getNFTData({ payload }) {
  const { error, response } = yield call(getCall, `/nft/getNftData/${payload}`);
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else yield put(setNFTData(response['data']['body']['activities']));
};

function* getNFTDataMarketplace({ payload }) {
  const { error, response } = yield call(getCall, `/nft/getNftDataMarketplace/${payload}`);
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else yield put(setNFTDataMarketplace(response['data']['body']));
};

function* getLikedNfts() {
  const { error, response } = yield call(getCall, `/nft/getallLiked`);
  console.log("****************** response", response);
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else yield put(setLikedNfts(response['data']['body']));
};

function* getAllCollections() {
  const { error, response } = yield call(getCall, `/nft/getallCollection`);
  console.log("****************** response", response);
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else yield put(setAllCollections(response['data']['body']));
};

function* actionWatcher() {
  yield takeEvery('CREATE_NFT', createNFT);
  yield takeEvery('TOGGLE_HEART', toggleHeart);
  yield takeEvery('GET_SINGLE_NFT', getSingleNFT);
  yield takeEvery('GET_CATEGORY_NFT', getCategoryNFT);
  yield takeEvery('CREATE_COLLECTION', createCollection);
  yield takeEvery('GET_COLLECTION_DATA', getCollectionData);
  yield takeEvery('GET_CATEGORIES', getCategories);
  yield takeEvery('GET_COLLECTION_NAME', getCollectionsName);
  yield takeEvery('GET_SIGNLE_COLLECTION', getSingleCollection);
  yield takeEvery('GET_MY_COLLECTIONS', getMyCollections);
  yield takeEvery('GET_BASIC_COLLECTION_DETAILS', getBasicCollectionDetails);
  yield takeEvery('GET_USER_CREATED_NFT', getUserCreatedNFT);
  yield takeEvery('GET_USER_ACTIVITY', getUserActivity);
  yield takeEvery('GET_USER_FAV_NFTS', getUserFavNFTs);
  yield takeEvery('GET_USER_COLLECTED_NFTS', getUserCollectedNFTs);
  yield takeEvery('GET_NFT_DATA', getNFTData);
  yield takeEvery('GET_NFT_DATA_MARKETPLACE', getNFTDataMarketplace);
  yield takeEvery('GET_LIKED_NFTS', getLikedNfts);
  yield takeEvery('GET_ALL_COLLECTIONS', getAllCollections);
}

export default function* rootSaga() {
  yield all([actionWatcher()]);
}

function postCall({ path, payload }) {
  return axios
    .post(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}

function getCall(path) {
  return axios
    .get(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}

function deleteCall(path) {
  return axios
    .delete(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}

function putCall({ path, payload }) {
  return axios
    .put(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}