import EventBus from "eventing-bus";
import { connect } from "react-redux";
import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { TextField } from '@material-ui/core';
import { Send, CheckCircle, Info, Error, Done, Facebook, Twitter, Instagram, LinkedIn, LiveTvRounded } from '@material-ui/icons';

import './index.css';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { activity, sliderone, slidertwo } from './recentActivity';

class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  };

  render() {

    const owl_option = {
      margin: 10,
      nav: false,
      loop: true,
      infinite: true,
      autoplay: true,
      responsive: {
        0: {
          items: 1,
        },
        400: {
          items: 1,
        },
        600: {
          items: 2,
        },
        1100: {
          items: 4,
        }
      },
    };

    const owl_option_2 = {
      margin: 10,
      nav: false,
      loop: true,
      infinite: true,
      autoplay: false,
      items: 1
    };



    return (
      <div className='landing-nft'>

        <Navbar />
        {/* Haeder Section */}
        <section className="header-section" style={{ backgroundImage: `url(${require("../../static/images/landing/landingnft/banner-bg.png")})` }}>
          <div className="auto-container">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="textheader-main">
                  <h5>Discover, and sell</h5>
                  <h1>Extraordinary <br /> NFTs</h1>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec commodo dignissim finibus. Aenean sed feugiat turpis. Etiam dapibus congue justo ut tempor.</p>
                  <button type="button" className="btn-style-one">Get Started <i className='icon fa fa-angle-right'></i></button>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="imageback-header ">
                  {/* <img src={require("../../static/images/landing/landingnft/banner-img.png")} alt="" />
                   */}
                  <div dangerouslySetInnerHTML={{
                    __html: `
                        <video autoPlay="true" muted="true" preload="auto" webkit-playsinline="true" playsinline="true">
                            <source src="./banner-video.mp4" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    `}}></div>
                </div>
              </div>
            </div>
          </div>

        </section>

        <section className="pick-design" >
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="inner-text">
                  <div className="sec-title">
                    <h2>Top 10 Picks by Designcollector</h2>
                  </div>
                  <div className="text-pick-main">
                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here,</p>
                    <button type="button" className="btn-style-one">Get Started <i className='icon fa fa-angle-right'></i></button>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="image-pick">
                  <img src={require("../../static/images/landing/landingnft/top-area-img.png")} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>



        <section className="world-first" style={{ backgroundImage: `url(${require("../../static/images/landing/landingnft/pick-bg.png")})` }}>
          <div className="auto-container">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="image-pick">
                  <img src={require("../../static/images/landing/landingnft/nft-market-place.png")} alt="" />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="text-pick-main">
                  <div className="sec-title">
                    <h2>on the world's first & largest NFT<br /><span className="head-two">Marketplace</span></h2>
                    <h4>Influencers on our network</h4>
                  </div>
                  <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here,</p>
                  <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of</p>
                  <div className="big-text">
                    <h2 className="big-text-four">2.5M</h2>
                  </div>
                  <button type="button" className="btn-style-one">Get Started <i className='icon fa fa-angle-right'></i></button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="our-benefits" className="our-benefits" >
          <div className="auto-container">
            <div className="row">
              <div className="col-12">
                <div className="sec-title text-center">
                  <h2>Our Benefits</h2>
                  <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal.</p>
                </div>
              </div>
              <div className="benifits-block col-lg-4 col-md-6 col-12">
                <div className="benifits-inner">
                  <div className="img-area">
                    <img src={require("../../static/images/landing/landingnft/our-benefit-one.png")} alt="" />
                  </div>
                  <div className="text-box">
                    <h2>Fully Automated </h2>
                    <p>It is a long established fact that a reader will be distracted by the readable </p>
                  </div>
                </div>
              </div>
              <div className="benifits-block col-lg-4 col-md-6 col-12">
                <div className="benifits-inner">
                  <div className="img-area">
                    <img src={require("../../static/images/landing/landingnft/our-benefit-three.png")} alt="" />
                  </div>
                  <div className="text-box">
                    <h2>No Downlaod</h2>
                    <p>It is a long established fact that a reader will be distracted by the readable </p>
                  </div>
                </div>
              </div>
              <div className="benifits-block col-lg-4 col-md-6 col-12">
                <div className="benifits-inner">
                  <div className="img-area">
                    <img src={require("../../static/images/landing/landingnft/our-benefit-five.png")} alt="" />
                  </div>
                  <div className="text-box">
                    <h2>Easy Setup</h2>
                    <p>It is a long established fact that a reader will be distracted by the readable </p>
                  </div>
                </div>
              </div>
              <div className="benifits-block col-lg-4 col-md-6 col-12">
                <div className="benifits-inner">
                  <div className="img-area">
                    <img src={require("../../static/images/landing/landingnft/our-benefit-two.png")} alt="" />
                  </div>
                  <div className="text-box">
                    <h2>Customer Support</h2>
                    <p>It is a long established fact that a reader will be distracted by the readable </p>
                  </div>
                </div>
              </div>
              <div className="benifits-block col-lg-4 col-md-6 col-12">
                <div className="benifits-inner">
                  <div className="img-area">
                    <img src={require("../../static/images/landing/landingnft/our-benefit-four.png")} alt="" />
                  </div>
                  <div className="text-box">
                    <h2>Mobile Friendly</h2>
                    <p>It is a long established fact that a reader will be distracted by the readable </p>
                  </div>
                </div>
              </div>
              <div className="benifits-block col-lg-4 col-md-6 col-12">
                <div className="benifits-inner">
                  <div className="img-area">
                    <img src={require("../../static/images/landing/landingnft/our-benefit-six.png")} alt="" />
                  </div>
                  <div className="text-box">
                    <h2>Safety and Security</h2>
                    <p>It is a long established fact that a reader will be distracted by the readable </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="toptrending" className="top-trending" >
          <div className="auto-container">
            <div className="row">
              <div className="col-12">
                <div className="sec-title text-center">
                  <h2>Top Trending collections</h2>
                  <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="first-slider">
                  <OwlCarousel className="slider-items owl-carousel ltf-owl" autoplaySpeed={3000}  {...owl_option}>
                    {sliderone.map(item =>
                      <div class="item">
                        <div className="card">
                          <img src={require(`../../static/images/landing/landingnft/${item.image}`)} alt="" />
                          <div className="down-trending">
                            <h4>{item.name}</h4>
                            <div className="outer-main-content">
                              <div className="left-watchlist">
                                <p className="">{item.token_watchlist}</p>
                                <p className="">{item.bid}</p>
                                <p className="">{item.price}</p>
                              </div>
                              <div className="heart text-center">
                                <p>{item.number}</p>
                                <img src={require(`../../static/images/landing/landingnft/${item.heart}`)} alt="" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="recent-activity" className="recent-activity " style={{ backgroundImage: `url(${require("../../static/images/landing/landingnft/recent-bg.png")})` }}>
          <div className="auto-container">
            <div className="row">
              <div className="col-12">
                <div className="sec-title text-center">
                  <h2>Recent Activity</h2>
                  <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal.</p>
                </div>
              </div>
              <OwlCarousel className="slider-items owl-carousel ltf-owl" autoplaySpeed={3000}  {...owl_option_2}>
                {activity.map(item =>
                  <div className="recent-box">
                    <div className="recent-inner">
                      <div className="card-recent">
                        <img src={require(`../../static/images/landing/landingnft/${item.image}`)} alt="" />
                      </div>
                      <div className="recent-people">
                        <div className="heading">

                          <h5>{item.name}</h5>
                        </div>
                        <div className="automated">
                          <img src={require(`../../static/images/landing/landingnft/${item.icon}`)} alt="" />
                          <p>{item.desc}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </OwlCarousel>
            </div>
          </div>
        </section>

        {/* <section className="clients-testimonials" style={{ backgroundImage: `url(${require("../../static/images/landing/landingnft/testimonial-main-back.png")})` }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="left-testimonial">
                  <img src={require("../../static/images/landing/landingnft/client-test-left.png")} alt="" />
                  <h2>Client Testimonials</h2>
                  <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed
                    to using 'Content here,</p>
                  <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution.</p>
                </div>
              </div>
              <div className="col-lg-5 col-md-12 col-12 padding-left-client">
                <div className="row">
                  <div className="col-lg-6 col-md-6 colum-one">
                    <div className="card-automated one shadow">
                      <div className="img">
                        <img src={require("../../static/images/landing/landingnft/client-people-one.png")} alt="" />
                      </div>
                      <div className="rating-star">
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star"></span>
                        <span class="fa fa-star"></span>
                      </div>
                      <h5>Fully Automated</h5>
                      <p>It is a long established fact that a reader will be distracted by the readable content</p>
                    </div>
                    <div className="card-automated one shadow">
                      <div className="img">
                        <img src={require("../../static/images/landing/landingnft/client-people-four.png")} alt="" />
                      </div>
                      <div className="rating-star">
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star"></span>
                        <span class="fa fa-star"></span>
                      </div>
                      <h5>Fully Automated</h5>
                      <p>It is a long established fact that a reader will be distracted by the readable content</p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 colum-two">
                    <div className="card-automated shadow">
                      <div className="img">
                        <img src={require("../../static/images/landing/landingnft/client-people-two.png")} alt="" />
                      </div>
                      <div className="rating-star">
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star"></span>
                        <span class="fa fa-star"></span>
                      </div>
                      <h5>Fully Automated</h5>
                      <p>It is a long established fact that a reader will be distracted by the readable content</p>
                    </div>
                    <div className="card-automated shadow">
                      <div className="img">
                        <img src={require("../../static/images/landing/landingnft/client-people-three.png")} alt="" />
                      </div>
                      <div className="rating-star">
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star"></span>
                        <span class="fa fa-star"></span>
                      </div>
                      <h5>Fully Automated</h5>
                      <p>It is a long established fact that a reader will be distracted by the readable content</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </section> */}

        <section id="contact" className="lets-talk" style={{ backgroundImage: `url(${require("../../static/images/landing/landingnft/contact-bg.png")})` }}>
          <div className="auto-container">
            <div className="row">
              <div className="col-12">
                <div className="sec-title text-center">
                  <h2>Let's Talk</h2>
                  <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. <br /> The point of using Lorem Ipsum is that it has a more-or-less normal.</p>
                </div>
              </div>
              <div className="col-lg-8 col-md-8 col-12 offset-md-2 offset-lg-2">
                <div className="contact-form">
                  <div className="group-form">
                    <TextField
                      fullWidth
                      // value={name}
                      label="First Name"
                      margin="normal"
                      id="standard-name"
                    // onChange={this.handleChange('name')}
                    />
                  </div>
                  <div className="group-form">
                    <TextField

                      fullWidth
                      label="Last Name"
                      margin="normal"
                      // value={email}
                      // error={!emailValid}
                      id="standard-name"
                    // onChange={this.handleChange('email')}
                    // helperText={!emailValid ? 'Invalid Email !!!' : ' '}
                    />
                  </div>
                  <div className="group-form">
                    <TextField
                      fullWidth
                      label="Integration User Name"
                      margin="normal"
                      id="standard-name"
                    // value={contact_no}
                    // onChange={this.handleChange('contact_no')}
                    />
                  </div>

                  <div className="group-form">
                    <TextField
                      fullWidth
                      label="Email"
                      margin="normal"
                      // value={message}
                      id="standard-name"
                    // onChange={this.handleChange('message')}
                    />
                  </div>
                  <div className="group-form">
                    <TextField
                      fullWidth
                      label="Phone"
                      margin="normal"
                      // value={message}
                      id="standard-name"
                    // onChange={this.handleChange('message')}
                    />
                  </div>
                  <div className="group-form">
                    <TextField
                      fullWidth
                      label="Company"
                      margin="normal"
                      // value={message}
                      id="standard-name"
                    // onChange={this.handleChange('message')}
                    />
                  </div>
                  <div className="group-form">
                    <TextField
                      fullWidth
                      label="Discription"
                      margin="normal"
                      // value={message}
                      id="standard-name"
                    // onChange={this.handleChange('message')}
                    />
                  </div>
                  <div className="group-form text-center">
                    <button type="button" className="btn-style-one">Submit <i className='icon fa fa-angle-right'></i></button>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />

      </div>
    );
  }
}


const mapDispatchToProps = {
};

const mapStateToProps = ({ }) => {
  return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(Landing);