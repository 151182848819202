import EventBus from "eventing-bus";
import { connect } from "react-redux";
import moment from 'moment';
import React, { Component } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button, TextField } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Send, CheckCircle, Info, Error, Done, Facebook, Twitter, Instagram, LinkedIn, LiveTvRounded } from '@material-ui/icons';

import './index.css';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { web3 } from '../../store/contract'
import { getUserCollectedNFTs, getUserCreatedNFT, getUserFavNFTs, getUserActivity } from '../../store/actions/NFT';
import { updateUserProfile, getUserData, } from '../../store/actions/Auth'
import { Redirect } from 'react-router'


class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bio: '',
      file: '',
      email: '',
      imageNew: '',
      customurl: '',
      displayname: '',
      twitterName: '',
      isLoading: false,
      imageUploaded: '',
      selectedFile: null,
      personalProfile: '',
      selected: 'collected',
    };
  };

  componentDidMount() {
    this.setState(
      this.handleUserCollected(this.props.address, 'collected'),
      this.handleUserData(this.props.address)
    )
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevProps.userprofileData !== this.props.userprofileData) {

  //     const { name, customeurl, email, bio, twitterName, portfolioLink, avatar } = this.props.userprofileData
  //     this.setState({ selectedFile: avatar, displayname: name, customurl: customeurl, twitterName: twitterName, email: email, bio: bio, personalProfile: portfolioLink, isLoading: false })
  //   }
  // }

  componentWillReceiveProps({ userCollectedNFTs, userFavNFTs, userCreatedNFTs, userActivity }) {
    this.setState({ userCollectedNFTs, userFavNFTs, userCreatedNFTs, userActivity })
  };

  handleUserData = (address) => {
    this.props.getUserData(address);

  };

  handleUserCollected = (address, selected) => {
    this.props.getUserCollectedNFTs(address);
    this.setState({ selected });
  };



  handleUserCreated = (address, selected) => {
    this.props.getUserCreatedNFT(address);
    this.setState({ selected });
  };

  handleUserFavorited = (address, selected) => {
    this.props.getUserFavNFTs(address);
    this.setState({ selected });
  };

  handleUserActivities = (address, eventType, selected) => {
    this.props.getUserActivity({ address, eventType });
    this.setState({ selected });
  };

  copy = () => EventBus.publish("success", 'Wallet Address Copied');


  render() {

    let { address, userCollectedNFTs, userprofileData } = this.props
    let { selected, bio, selectedFile, userFavNFTs, userCreatedNFTs, userActivity } = this.state
    if (!address) {
      return <Redirect to="/" />
    }

    return (
      <div className='profile-nft'>

        <Navbar />
        {/* Haeder Section */}
        <section className="header-section" style={{ backgroundImage: `url(${require("../../static/images/landing/landingnft/profile/banner-back1.png")})` }}>
        </section>

        <div className="first-section" style={{ backgroundImage: `url(${require("../../static/images/landing/landingnft/profile/profile-bg.png")})` }}>
          <div className="auto-container">
            <div className="tocaya">
              <div className="images-mid text-center">
                <img src={userprofileData.profileImageUrl == "null" ? require("../../static/images/landing/landingnft/profile/avtar3.png") : userprofileData.profileImageUrl} className="image-avatar" alt="" />
                <h3>{`${userprofileData.name == '' ? 'Unnamed' : userprofileData.name}`}</h3>

                {/* <h5>{displayname} <span className="At">@{twitterName} | <span><img src={require("../../static/images/landing/landingnft/profile/global.png")} alt="" /></span > <span className="mail">{personalProfile}</span></span></h5> */}
                <CopyToClipboard text={address} onCopy={this.copy}>
                  <button className="button-address" type="button">{address}<span className="button-copy"><i class="fa fa-files-o" aria-hidden="true"></i></span></button>
                </CopyToClipboard>
                <p>Joined {`${moment(userprofileData.createdAt).format('dddd, MMMM Do YYYY')}`}</p>
                <div className="profile-setting-area">
                  <Link className="profile-area" type="button"><img src={require("../../static/images/landing/landingnft/profile/share-icon.png")} alt="" /></Link>
                  <Link className="profile-area" to="/editprofile" ><img src={require("../../static/images/landing/landingnft/profile/settings-icon.png")} alt="" /></Link>
                </div>

                <p>{bio}</p>
                <div className="row">
                  {/* <div className="buttonss d-flex justify-content-center">
                    <button className="button-one shadow" type="button">Follow</button>
                    <button className="button-two shadow" type="button"><img src={require("../../static/images/landing/landingnft/profile/export.png")} alt="" /></button>
                    <button className="button-three shadow" type="button"><img src={require("../../static/images/landing/landingnft/profile/three-dot.png")} alt="" /></button>
                    
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="tabs-accounts-cards">
            <div className="auto-container">
              <div className="row">
                <div className="col-12">
                  <div className="card-tab-menu">

                    <button type="button" className={selected == 'collected' && "active"} onClick={() => { this.handleUserCollected(address, 'collected') }}>Collected<i class="icon fa fa-image"></i></button>
                    <button type="button" className={selected == 'created' && "active"} onClick={() => { this.handleUserCreated(address, 'created') }}>Created<i class="icon fa fa-edit"></i></button>
                    <button type="button" className={selected == 'favourited' && "active"} onClick={() => { this.handleUserFavorited(address, 'favourited') }}>Favourited<i class="icon fa fa-heart"></i></button>
                    <button type="button" className={selected == 'activity' && "active"} onClick={() => { this.handleUserActivities(address, 'all', 'activity') }}>Activity<i class="icon fa fa-stumbleupon"></i></button>
                    {/* <button type="button">Hidden<i class="icon fa fa-eye-slash"></i></button> */}
                    {/* <button type="button">Offers<i class="icon fa fa-tag"></i></button>
                    <button type="button">Listings<i class="icon fa fa-table"></i></button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div className=" first-sectionss">
            <div className="auto-container">
              <div className="row">
                <div className="col-12">
                  <div className="search-area row">
                    <div className="group-form search-form col-lg-4 col-md-6 col-sm-12">
                      <TextField
                        fullWidth
                        // value={name}
                        label="Search"
                        margin="normal"
                        id="standard-name"
                      // onChange={this.handleChange('name')}
                      />
                    </div>
                    <div className="group-form single-form col-lg-3 col-md-6 col-sm-12">
                      <TextField
                        fullWidth
                        // value={name}
                        label="Single items"
                        margin="normal"
                        id="standard-name"
                      // onChange={this.handleChange('name')}
                      />
                      <i className="icon"><img src={require("../../static/images/landing/landingnft/category/down-cat.png")} alt="" /></i>
                    </div>
                    <div className="group-form single-form col-lg-3 col-md-6 col-sm-12">
                      <TextField
                        fullWidth
                        // value={name}
                        label="Recently received"
                        margin="normal"
                        id="standard-name"
                      // onChange={this.handleChange('name')}
                      />
                      <i className="icon"><img src={require("../../static/images/landing/landingnft/category/down-cat.png")} alt="" /></i>
                    </div>
                    <div className="group-form button-form col-lg-2 col-md-6 col-sm-12">
                      <button className="expend-button"><img src={require("../../static/images/landing/landingnft/category/expend.png")} alt="" /></button>
                      <button className="expend-button"><img src={require("../../static/images/landing/landingnft/category/expend-2.png")} alt="" /></button>
                    </div>
                  </div>
                </div>

                {selected == 'collected' && userCollectedNFTs?.map(data =>
                  <div className="col-lg-3 col-md-4 col-12">
                    <div className="card">
                      <div className="upper-divs-triple">
                        <div className="left-side">
                          <img src={require("../../static/images/landing/landingnft/category/mercedes.png")} alt="" />
                          <img src={require("../../static/images/landing/landingnft/category/menn.png")} className="margin-ll" alt="" />
                          <img src={require("../../static/images/landing/landingnft/category/checktick.png")} className="margin-lll" alt="" />
                        </div>
                        <div className="right-side">
                          <img src={require("../../static/images/landing/landingnft/category/dot-nft.png")} alt="" />
                        </div>
                      </div>
                      <img src={`${data['image_url']}`} className="div-image-card" alt="" />
                      <div className="down-trending">
                        <h4>{data['name']}</h4>
                        <div className="outer-main-content">
                          <div className="left-watchlist">
                            <p className="">watchlist.eth</p>
                            <p className="">Minimum bid 1/1</p>
                            <p className="orange-color">wETH</p>
                          </div>
                          <div className="heart text-center">
                            <p>976</p>
                            {/* empty Icon */}
                            {/* <img src={require("../../static/images/landing/landingnft/category/unlikeHeart.png")} alt="" /> */}
                            {/* Fill Icon */}
                            <img src={require("../../static/images/landing/landingnft/category/likeHeart.png")} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {selected == 'created' && userCreatedNFTs?.map(data =>
                  <div className="col-lg-3 col-md-4 col-12">
                    <div className="card">
                      <div className="upper-divs-triple">
                        <div className="left-side">
                          <img src={require("../../static/images/landing/landingnft/category/mercedes.png")} alt="" />
                          <img src={require("../../static/images/landing/landingnft/category/menn.png")} className="margin-ll" alt="" />
                          <img src={require("../../static/images/landing/landingnft/category/checktick.png")} className="margin-lll" alt="" />
                        </div>
                        <div className="right-side">
                          <img src={require("../../static/images/landing/landingnft/category/dot-nft.png")} alt="" />
                        </div>
                      </div>
                      <img src={`${data['meta']?.image?.url['ORIGINAL']}`} className="div-image-card" alt="" />
                      <div className="down-trending">
                        <h4>{data['meta']['name']}</h4>
                        <div className="outer-main-content">
                          <div className="left-watchlist">
                            <p className="">watchlist.eth</p>
                            <p className="">Minimum bid 1/1</p>
                            <p className="orange-color">wETH</p>
                          </div>
                          <div className="heart text-center">
                            <p>976</p>
                            {/* empty Icon */}
                            {/* <img src={require("../../static/images/landing/landingnft/category/unlikeHeart.png")} alt="" /> */}
                            {/* Fill Icon */}
                            <img src={require("../../static/images/landing/landingnft/category/likeHeart.png")} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {selected == 'favourited' && userFavNFTs?.map(data =>
                  <div className="col-lg-3 col-md-4 col-12">
                    <div className="card">
                      <div className="upper-divs-triple">
                        <div className="left-side">
                          <img src={require("../../static/images/landing/landingnft/category/mercedes.png")} alt="" />
                          <img src={require("../../static/images/landing/landingnft/category/menn.png")} className="margin-ll" alt="" />
                          <img src={require("../../static/images/landing/landingnft/category/checktick.png")} className="margin-lll" alt="" />
                        </div>
                        <div className="right-side">
                          <img src={require("../../static/images/landing/landingnft/category/dot-nft.png")} alt="" />
                        </div>
                      </div>
                      <img src={`${data.nftUri}`} className="div-image-card" alt="" />
                      <div className="down-trending">
                        <h4>{data.category}</h4>
                        <div className="outer-main-content">
                          <div className="left-watchlist">
                            <p className="">watchlist.eth</p>
                            <p className="">Minimum bid 1/1</p>
                            <p className="orange-color">wETH</p>
                          </div>
                          <div className="heart text-center">
                            <p>976</p>
                            {/* empty Icon */}
                            {/* <img src={require("../../static/images/landing/landingnft/category/unlikeHeart.png")} alt="" /> */}
                            {/* Fill Icon */}
                            <img src={require("../../static/images/landing/landingnft/category/likeHeart.png")} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* activity panel */}

                {selected == 'activity' ?
                  <div className="activity-block col-lg-8 col-md-12">
                    <div className="activity-table table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th className="item">Item</th>
                            <th>Price</th>
                            <th>Quantity</th>
                            <th>From</th>
                            <th>To</th>
                            <th>Time</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            userActivity?.map(data => <tr>
                              <td>
                                <p className="time">{data['event_type']} <i className="icon fa fa-tag"></i></p>
                                <div className="image-box">
                                  <img src={`${data.asset['image_url']}`} alt="" />
                                </div>
                                <h4> <a href="#">{`${data['asset']['name']}`}</a><span>{`${data['collection_slug']}`}</span></h4>
                              </td>
                              <td><p className="price"><i className="icon"><img src={require("../../static/images/landing/activity/eth-1.png")} alt="" /></i> {data['ending_price'] !== null ? `${web3.utils.fromWei(data?.['ending_price'], 'ether')}` : 0.00} </p></td>
                              <td>{data['quantity']}</td>
                              <td>
                                {`${data['from_account'] ? data['from_account']?.['address'].slice(0, 8) + '...' : '---'}`}
                              </td>
                              <td>{`${data['to_account'] ? data['to_account']?.['address'].slice(0, 8) + '...' : '---'}`}</td>
                              <td><p className="time">{`${moment(data['created_date']).fromNow()}`}</p></td>
                            </tr>)
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                  : ''}
                {selected == 'activity' ?
                  <div className="buttons-block col-lg-4 col-md-12">
                    <div className="buttons-inner">
                      <div className="filers-area">
                        <h5>Filters</h5>
                        {/* <a className="reset-filters" href="#">Reset filter</a> */}
                      </div>
                      <div id="accordion">
                        <div class="card">
                          <div class="card-header" id="headingOne">
                            <h5 class="mb-0">
                              <button class="red btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                Event Type
                              </button>
                            </h5>
                          </div>
                          <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                            <div class="card-body">
                              <ul className="filter-list">
                                <li className={`listings `}>
                                  <button onClick={() => { this.handleUserActivities(address, 'all', 'activity') }}><i className="icon fa fa-tag"></i> All</button>
                                </li>
                                <li className={`purchase`}>
                                  <button onClick={() => { this.handleUserActivities(address, 'transfer', 'activity') }}><i className="icon fa fa-dollar"></i>Purchase </button>
                                </li>
                                <li className={`sales `}>
                                  <button onClick={() => { this.handleUserActivities(address, 'successful', 'activity') }}><i className="icon fa fa-flash"></i>Sales </button>
                                </li>
                                <li className={`transfer`}>
                                  <button onClick={() => { this.handleUserActivities(address, 'created', 'activity') }}><i className="icon fa fa-flash"></i>Created </button>
                                </li>

                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  : ''}
              </div>
            </div>
          </div>
        </div>
        <Footer />
        {/* <Modal isOpen={false} toggle={this.props.toggleBuyWallet} className="register-modal buy-token-modal">
          <div class="icon-area">
            <h1>6 <br></br><span>Days Left</span></h1>
          </div>
          <ModalHeader toggle={this.props.toggleBuyWallet}>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>
          <ModalBody className="modal-body">
            <div className="text-head-main">
              <h1>JOIN AND WIN</h1>
              <h4>Featured Lottery</h4>
              <h6>What is Featured Lottery?</h6>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus facilisis at dui et mollis. Quisque metus lacus, vehicula id gravida ut, pharetra id ex. Maecenas ipsum lorem, imperdiet non egestas a, aliquam a est. Proin gravida neque at nunc egestas, vitae scelerisque eros ullamcorper. Phasellus congue nulla tellus, sit amet molestie velit lacinia in. Etiam malesuada placerat nibh</p>
            </div>
          </ModalBody>
        </Modal> */}


        {/*************************** Trems Modal ****************************************/}

        {/* <Modal isOpen={true} toggle={this.props.toggleBuyWallet} className="trems-modal">
          <div class="icon-area">
            <h1>Marketplace Terms of Services </h1>
          </div>
          <ModalHeader toggle={this.props.toggleBuyWallet}>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>
          <ModalBody className="modal-body">
            <div className="text-head-main">
              <p>Please take a few minutes to read and understand Marketplace terms of services. To continue, you'll need to accept the terms of services by checking the box.</p>

              <div class="form-group">
                <input type="checkbox" id="age" />
                <label for="age">I am at least 13 years old</label>
              </div>
              <div class="form-group">
                <input type="checkbox" id="terms" />
                <label for="terms">I accept Marketplace terms of services</label>
              </div>

              <button type="button" className="btn-style-one">Proceed <i className='icon fa fa-angle-right'></i></button>
              <button type="button" className="btn-style-two">Signout <i className='icon'><img src={require("../../static/images/landing/landingnft/profile/logout.png")} alt="" /></i></button>
            </div>
          </ModalBody>
        </Modal> */}

        {/*************************** Steps Modal ****************************************/}

        {/* 
        <Modal isOpen={true} toggle={this.props.toggleBuyWallet} className="steps-modal">
          <div class="icon-area">
            <h1>Follow steps</h1>
          </div>
          <ModalHeader toggle={this.props.toggleBuyWallet}>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>
          <ModalBody className="modal-body">
            <div className="text-head-main">
              <div className="step-box">
                <div className="inner-box">
                  <div className="icon-box">
                    <img src={require("../../static/images/landing/landingnft/profile/check-step-ok.png")} alt="" />
                  </div>
                  <div className="text-box">
                    <h4>Upload</h4>
                    <p>Uploading of all media assets and metadata to IPFS</p>
                  </div>
                </div>
              </div>

              <div className="step-box lodding-box">
                <div className="inner-box">
                  <div className="icon-box">
                    <img src={require("../../static/images/landing/landingnft/profile/loding.png")} alt="" />
                  </div>
                  <div className="text-box">
                    <h4>Mint</h4>
                    <p>Send transaction to create your NFT</p>
                  </div>
                </div>
              </div>

              <div className="step-box wait-box">
                <div className="inner-box">
                  <div className="icon-box">
                    <img src={require("../../static/images/landing/landingnft/profile/check-step.png")} alt="" />
                  </div>
                  <div className="text-box">
                    <h4>Approve</h4>
                    <p>This transaction is conducted only one per collection</p>
                  </div>
                </div>
              </div>

              <div className="step-box wait-box">
                <div className="inner-box">
                  <div className="icon-box">
                    <img src={require("../../static/images/landing/landingnft/profile/check-step.png")} alt="" />
                  </div>
                  <div className="text-box">
                    <h4>Put on sale</h4>
                    <p>Sign message to set fixed price</p>
                  </div>
                </div>
              </div>
              <div className="btn-box text-center">
                <button type="button" className="btn-style-one">Cancel <i className='icon fa fa-times'></i></button>
              </div>

            </div>
          </ModalBody>
        </Modal> */}

      </div>
    );
  }

}

const mapDispatchToProps = { updateUserProfile, getUserData, getUserFavNFTs, getUserCollectedNFTs, getUserCreatedNFT, getUserActivity };

const mapStateToProps = ({ Auth, NFT }) => {

  let { address, userprofileData } = Auth;
  let { userCollectedNFTs, userFavNFTs, userCreatedNFTs, userActivity } = NFT;
  return { address, userprofileData, userFavNFTs, userCollectedNFTs, userCreatedNFTs, userActivity }
};


export default connect(mapStateToProps, mapDispatchToProps)(Profile);