import EventBus from "eventing-bus";
import { connect } from "react-redux";
import React, { Component } from 'react';
import Countdown from 'react-countdown';
import { Button, TextField } from '@material-ui/core';
import { Link } from "react-router-dom";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

import './index.css';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getSingleNFT, setLoader } from '../../store/actions/NFT';
import { TokenABI, TokenAddress, web3, Marketplace, MarketplaceAddress } from '../../store/contract';


class SingleNFT extends Component {
  constructor(props) {
    super(props);
    this.state = {
      singleNFTData: {},
      attributesNFT: {},
      isBidModal: false,
      isApproveBid: false,
      amount: '',
      address: '',
      balance: '',
      id: '',
      owner: '',
      fee: '',
      allBids: '',
      selectedTokenAddress: '',
    }
  };

  async componentDidMount() {
    let tokenId = 88;
    let tokenAddress = '0xa06b6125f434b4eddaf62bea2757ae19bbf8052a';
    const Token = new web3.eth.Contract(TokenABI, tokenAddress);

    console.log("******Token Data::", await Token.methods.tokenURI(tokenId).call());
    console.log("******Marketplace::", await Marketplace.methods.orderByTokenId(tokenAddress, tokenId).call());

    let { location } = this.props;
    if (location.pathname.match('singleNFT')) {
      let data = location.pathname.split('/')[--location.pathname.split('/').length];
      this.props.getSingleNFT(data);
    };
    let address = (await web3.currentProvider.enable())[0];
    let getBalance = await (await web3.eth.getBalance(address));
    let balance = web3.utils.fromWei(getBalance, 'ether');
    let id = await Token.methods.balanceOf(address).call();
    let owner = await Token.methods.ownerOf(id).call();
    let fee = await Marketplace.methods.cutPerMillion().call();
    let allBids = await Marketplace.methods.allBiddersByTokenId(TokenAddress, id).call();

    this.setState({ address, balance, id, owner, fee, allBids, selectedTokenAddress: TokenAddress });
  };

  componentWillReceiveProps({ singleNFT }) {
    console.log("*********singleNFT::", singleNFT);
    fetch(singleNFT['nftUri'])
      .then(response => response.json())
      .then(response => this.setState({ singleNFTData: response, attributesNFT: response['attributes'][0] }))
      .catch(error => EventBus.publish("error", "Invalid IPFS Path Can't Find Data"));
  };

  componentDidUpdate(prevProps, prevState) {
    let { collectionData } = this.props;
    if (prevProps.collectionData !== collectionData) {
      if (collectionData) this.setState({ selectedTokenAddress: collectionData['tokenAddress'] });
    }
  };

  copied = () => EventBus.publish("info", 'Address Copied');

  handleReload = () => {
    EventBus.publish('info', "Auction Time is Completed");
  };

  toggleBidModal = () => this.setState({ isBidModal: !this.state.isBidModal });
  toggleApproveBidModal = () => this.setState({ isBidModal: !this.state.isBidModal });

  handlePlaceBid = async () => {
    let { amount, selectedTokenAddress, id } = this.state

    if (amount == '' || amount == 0) {
      EventBus.publish('info', `Please Enter Some Amount`);
      return;
    };

    let bidAmount = web3.utils.toWei(amount, 'ether');
    console.log("***********bidAmount:::", bidAmount);
    console.log("***********selectedTokenAddress:::", selectedTokenAddress);
    console.log("***********id:::", id);

    // try {
    // Start Loader
    this.props.setLoader({ message: 'Placing a Bid...', status: true });
    let from = (await web3.currentProvider.enable())[0];
    web3.eth.sendTransaction({
      from,
      value: 0,
      to: MarketplaceAddress,
      gasPrice: web3.utils.toHex(web3.utils.toWei('25', 'gwei')),
      data: Marketplace.methods.safePlaceBid(selectedTokenAddress, id, bidAmount).encodeABI(),
    }).on('transactionHash', (hash) => console.log(`*******hash =`, hash))
      .on('receipt', receipt => {
        EventBus.publish('error', `Bid Placed Successfully`);
        this.props.setLoader({ message: 'Bid is Placed', status: false })
      }).on('error', e => {
        this.props.setLoader({ message: 'Bid is not Placed', status: false })
        EventBus.publish('error', `Unable to a Placed`);
      });
    // } catch (e) {
    //   this.props.setLoader({ message: 'Bid is not Placed', status: false })
    //   EventBus.publish('error', `Unable to a Placed`);
    // }
  };



  render() {
    let { singleNFT } = this.props;
    let { singleNFTData, attributesNFT, isBidModal, isApproveBid, amount, address, balance, id, fee, allBids } = this.state;

    console.log("***********singleNFT:::", singleNFT);


    const renderer = ({ days, hours, minutes, seconds, completed }) => {
      if (completed) return <span>Auction is Completed</span>;
      else {
        return (
          <div className="row">
            <div className="counter-day counter-days">
              <h5>{days ? days : '-'}</h5>
              <p>Days</p>
            </div>
            <div className="counter-day counter-hour">
              <h5>{hours ? hours : '-'}</h5>
              <p>Hours</p>
            </div>
            <div className="counter-day counter-minute">
              <h5>{minutes ? minutes : '-'}</h5>
              <p>Minutes</p>
            </div>
            <div className="counter-day counter-seconds">
              <h5>{seconds ? seconds : '-'}</h5>
              <p>Seconds</p>
            </div>
          </div>
        );
      }
    };

    return (
      <div className='singlepage-nft'>
        <Navbar />
        {/* Product Section */}
        <section className="products-bid" style={{ backgroundImage: `url(${require("../../static/images/landing/singlepage/nftsingle-bg.png")})` }}>
          <div className="auto-container">
            <div className="row">
              <div className="image-block col-lg-4 col-md-12">
                <div className="image">
                  <img src={require("../../static/images/landing/singlepage/nft-single.png")} alt="" />
                </div>
                <div className="disctionbox">
                  <div className="inner-box">
                    <div className="heading-area">
                      <h3><i className="icon"><img src={require("../../static/images/landing/collection/des-icon.png")} alt="" /></i>Description</h3>
                    </div>
                    <div className="text-box" >
                      <p>Created by <Link to="#">BoredBunnyContract</Link></p>
                    </div>
                  </div>
                </div>

                <div className="disctionbox">
                  <div className="inner-box">
                    <div className="heading-area">
                      <h3><i className="icon"><img src={require("../../static/images/landing/collection/pro-icon.png")} alt="" /></i>Properties</h3>
                    </div>
                    <div className="text-box" >
                      <div className="details">
                        <div className="detail-box">
                          <h4>Backgrounds</h4>
                          <p>Basic backgrou...</p>
                          <span>87% have this trait</span>
                        </div>
                        <div className="detail-box">
                          <h4>Backgrounds</h4>
                          <p>Basic backgrou...</p>
                          <span>87% have this trait</span>
                        </div>
                        <div className="detail-box mr-0">
                          <h4>Backgrounds</h4>
                          <p>Basic backgrou...</p>
                          <span>87% have this trait</span>
                        </div>
                        <div className="detail-box">
                          <h4>Backgrounds</h4>
                          <p>Basic backgrou...</p>
                          <span>87% have this trait</span>
                        </div>
                        <div className="detail-box">
                          <h4>Backgrounds</h4>
                          <p>Basic backgrou...</p>
                          <span>87% have this trait</span>
                        </div>
                        <div className="detail-box mr-0">
                          <h4>Backgrounds</h4>
                          <p>Basic backgrou...</p>
                          <span>87% have this trait</span>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="disctionbox">
                  <div className="inner-box">
                    <div className="heading-area">
                      <h3><i className="icon"><img src={require("../../static/images/landing/collection/about-icon.png")} alt="" /></i>About NFT Bored Bad Bunny</h3>
                    </div>
                    <div className="text-box" >
                      <div className="about-box">
                        <div className="img-box">
                          <img src={require("../../static/images/landing/collection/about-img.png")} alt="" />
                        </div>
                        <div className="deta-box">
                          <p>After Sold Out in 17 Minutes Bored Bunny is back with a new collection. Meet Bored Bad Bunny a collection of 10,631 unique 3D well-designed Bad Bunnies - Floyd Mayweather, DJ Khaled, Jake Paul, David Dobrik, French Montana, Umar Kamani, Chantel Jeffries and many more celebrities have already joined the project.</p>
                        </div>
                      </div>
                      <ul className="social-links">
                        <li><Link to="#"><img src={require("../../static/images/landing/collection/web-icon.png")} alt="" /></Link></li>
                        <li><Link to="#"><img src={require("../../static/images/landing/collection/discord.png")} alt="" /></Link></li>
                        <li><Link to="#"><img src={require("../../static/images/landing/collection/instagram.png")} alt="" /></Link></li>
                        <li><Link to="#"><img src={require("../../static/images/landing/collection/dots.png")} alt="" /></Link></li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="disctionbox">
                  <div className="inner-box">
                    <div className="heading-area">
                      <h3><i className="icon"><img src={require("../../static/images/landing/collection/detaili-icn.png")} alt="" /></i>Details</h3>
                    </div>
                    <div className="text-box" >
                      <ul className="list">
                        <li>Contact address<span>0x96f9...2e26</span></li>
                        <li>Token ID<span>7224</span></li>
                        <li>Token standard<span>ERC-721</span></li>
                        <li>Blockchain<span>Ethereum</span></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="content-block col-lg-8 col-md-12">
                <div className="content-area">
                  <div className="btn-area">
                    <Link to="#" className="btn-style-one">Offer</Link>
                    <Link to="#" className="btn-top"><img src={require("../../static/images/landing/collection/icon-1.png")} alt="" /></Link>
                    <Link to="#" className="btn-top"><img src={require("../../static/images/landing/collection/icon-2.png")} alt="" /></Link>
                    <Link to="#" className="btn-top"><img src={require("../../static/images/landing/collection/icon-3.png")} alt="" /></Link>
                    <Link to="#" className="btn-top"><img src={require("../../static/images/landing/collection/dots.png")} alt="" /></Link>
                  </div>
                  <div className="title-area">
                    <h4>NFT Bored Bad Bunny</h4>
                    {/* <h2>{singleNFTData['name']}</h2> */}
                    <h2>Bad Bored Bunny #7223</h2>
                    <div className="favorite-area">
                      <Link className="ownedname" to="#">Owned by <span>D42174</span></Link>
                      <Link className="favorite-btn" to="#"><i className="fa fa-heart"></i> {singleNFT['totalLikes']} 9 favourites</Link>
                    </div>
                  </div>
                  <div className="heighst-bid-area">
                    <div className="contest-box">
                      <div className="inner-box">
                        <div className="heading-area">
                          <h4><i className="icon"><img src={require("../../static/images/landing/collection/clock-icon.png")} alt="" /></i>Sale ends February 5, 2022 at 5:13pm PKT <span className="icon-right"><img src={require("../../static/images/landing/collection/info-icon.png")} alt="" /></span></h4>
                        </div>
                        <div className="text-box" >
                          <span className="top-bid">Top bid</span>
                          <h3><i className="icon-eth"><img src={require("../../static/images/landing/collection/blk.png")} alt="" /></i>0.0023 <span className="small-text">($132.53)</span><i className="icon-right"><img src={require("../../static/images/landing/collection/top-arrow.png")} alt="" /></i></h3>
                          <button type="button" className="btn-style-one">Place bid <i className='icon'><img src={require("../../static/images/landing/collection/wallet-icon.png")} alt="" /></i></button>
                        </div>
                      </div>
                    </div>

                    <div className="contest-box table-box">
                      <div className="inner-box">
                        <div className="heading-area">
                          <h3><i className="icon"><img src={require("../../static/images/landing/collection/linsting-icon.png")} alt="" /></i>Listing</h3>
                        </div>
                        <div className="text-box" >
                          <div className="listing-table table-responsive">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th className="item">Price</th>
                                  <th>USD Price</th>
                                  <th>Expiration</th>
                                  <th>From</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <h5><i className="icon"><img src={require("../../static/images/landing/activity/blk-1.png")} alt="" /></i>0.0493 ETH</h5>
                                  </td>
                                  <td><p>$126.53</p></td>
                                  <td><p>in 12 days</p></td>

                                  <td><p>Jaypesobeats</p></td>
                                </tr>

                                <tr>
                                  <td>
                                    <h5><i className="icon"><img src={require("../../static/images/landing/activity/blk-1.png")} alt="" /></i>0.0521 ETH</h5>
                                  </td>
                                  <td><p>$132.53</p></td>
                                  <td><p>in 12 days</p></td>
                                  <td><p>misosalo</p></td>
                                </tr>

                                <tr>
                                  <td>
                                    <h5><i className="icon"><img src={require("../../static/images/landing/activity/blk-1.png")} alt="" /></i>0.0493 ETH</h5>
                                  </td>
                                  <td><p>$126.53</p></td>
                                  <td><p>in 12 days</p></td>

                                  <td><p>Jaypesobeats</p></td>
                                </tr>

                                <tr>
                                  <td>
                                    <h5><i className="icon"><img src={require("../../static/images/landing/activity/blk-1.png")} alt="" /></i>0.0521 ETH</h5>
                                  </td>
                                  <td><p>$126.53</p></td>
                                  <td><p>in 12 days</p></td>
                                  <td><p>misosalo</p></td>
                                </tr>

                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="contest-box table-box">
                      <div className="inner-box">
                        <div className="heading-area">
                          <h3><i className="icon"><img src={require("../../static/images/landing/collection/offers-icon.png")} alt="" /></i>Offers</h3>
                        </div>
                        <div className="text-box" >
                          <div className="listing-table table-responsive">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th className="item">Price</th>
                                  <th>Floor Difference</th>
                                  <th>Expiration</th>
                                  <th>From</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <h5><i className="icon"><img src={require("../../static/images/landing/activity/blk-1.png")} alt="" /></i>0.0493 ETH</h5>
                                  </td>
                                  <td><p>42% below</p></td>
                                  <td><p>in 12 days</p></td>

                                  <td><p>Jaypesobeats</p></td>
                                </tr>

                                <tr>
                                  <td>
                                    <h5><i className="icon"><img src={require("../../static/images/landing/activity/blk-1.png")} alt="" /></i>0.0521 ETH</h5>
                                  </td>
                                  <td><p>45% below</p></td>
                                  <td><p>in 12 days</p></td>
                                  <td><p>misosalo</p></td>
                                </tr>

                                <tr>
                                  <td>
                                    <h5><i className="icon"><img src={require("../../static/images/landing/activity/blk-1.png")} alt="" /></i>0.0493 ETH</h5>
                                  </td>
                                  <td><p>42% below</p></td>
                                  <td><p>in 12 days</p></td>

                                  <td><p>Jaypesobeats</p></td>
                                </tr>

                                <tr>
                                  <td>
                                    <h5><i className="icon"><img src={require("../../static/images/landing/activity/blk-1.png")} alt="" /></i>0.0521 ETH</h5>
                                  </td>
                                  <td><p>45% below</p></td>
                                  <td><p>in 12 days</p></td>
                                  <td><p>misosalo</p></td>
                                </tr>

                                <tr>
                                  <td>
                                    <h5><i className="icon"><img src={require("../../static/images/landing/activity/blk-1.png")} alt="" /></i>0.0493 ETH</h5>
                                  </td>
                                  <td><p>42% below</p></td>
                                  <td><p>in 12 days</p></td>

                                  <td><p>Jaypesobeats</p></td>
                                </tr>

                                <tr>
                                  <td>
                                    <h5><i className="icon"><img src={require("../../static/images/landing/activity/blk-1.png")} alt="" /></i>0.0521 ETH</h5>
                                  </td>
                                  <td><p>45% below</p></td>
                                  <td><p>in 12 days</p></td>
                                  <td><p>misosalo</p></td>
                                </tr>

                                <tr>
                                  <td>
                                    <h5><i className="icon"><img src={require("../../static/images/landing/activity/blk-1.png")} alt="" /></i>0.0493 ETH</h5>
                                  </td>
                                  <td><p>42% below</p></td>
                                  <td><p>in 12 days</p></td>

                                  <td><p>Jaypesobeats</p></td>
                                </tr>

                                <tr>
                                  <td>
                                    <h5><i className="icon"><img src={require("../../static/images/landing/activity/blk-1.png")} alt="" /></i>0.0521 ETH</h5>
                                  </td>
                                  <td><p>45% below</p></td>
                                  <td><p>in 12 days</p></td>
                                  <td><p>misosalo</p></td>
                                </tr>

                                <tr>
                                  <td>
                                    <h5><i className="icon"><img src={require("../../static/images/landing/activity/blk-1.png")} alt="" /></i>0.0493 ETH</h5>
                                  </td>
                                  <td><p>42% below</p></td>
                                  <td><p>in 12 days</p></td>

                                  <td><p>Jaypesobeats</p></td>
                                </tr>

                                <tr>
                                  <td>
                                    <h5><i className="icon"><img src={require("../../static/images/landing/activity/blk-1.png")} alt="" /></i>0.0521 ETH</h5>
                                  </td>
                                  <td><p>45% below</p></td>
                                  <td><p>in 12 days</p></td>
                                  <td><p>misosalo</p></td>
                                </tr>

                                <tr>
                                  <td>
                                    <h5><i className="icon"><img src={require("../../static/images/landing/activity/blk-1.png")} alt="" /></i>0.0493 ETH</h5>
                                  </td>
                                  <td><p>42% below</p></td>
                                  <td><p>in 12 days</p></td>

                                  <td><p>Jaypesobeats</p></td>
                                </tr>

                                <tr>
                                  <td>
                                    <h5><i className="icon"><img src={require("../../static/images/landing/activity/blk-1.png")} alt="" /></i>0.0521 ETH</h5>
                                  </td>
                                  <td><p>45% below</p></td>
                                  <td><p>in 12 days</p></td>
                                  <td><p>misosalo</p></td>
                                </tr>

                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="contest-box">
                      <div className="inner-box">
                        <div className="heading-area">
                          <h3><i className="icon"><img src={require("../../static/images/landing/collection/price-icon.png")} alt="" /></i>Price History</h3>
                        </div>
                        <div className="text-box" >
                          <button type="button" className="time-dropdown">All time <i className='icon'><img src={require("../../static/images/landing/collection/down.png")} alt="" /></i></button>
                          <div className="date-area">
                            <h2>No item activity yet</h2>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="content-heighst">
                      <h4>Highest bid</h4>
                      <h3>0.13 wETH</h3>
                    </div> */}
                    {/* <div className="art-btns-area">
                      <a href="#" className="art-btn"> <i className="icon"><img src={require("../../static/images/landing/singlepage/2.png")} alt="" /></i> {attributesNFT['selectedCategory']}</a>
                      <a href="#" className="art-btn">Unlockable</a>
                    </div> */}
                  </div>
                  {/* <p>Experience the art created and animated by Artificial Intelligence! Rewatch this video time and time again. This video will calm you down and become a perfect display piece for large-scale installations.</p>
                  <p>You will own a 100% unique... <a href="#" className="redmore">Read more</a> </p> */}
                  {/* <p>{singleNFTData['description']}</p>
                  <div className="bid-spec">
                    <ul className="creator">
                      <li className="active">Creator</li>
                      <li>
                        <CopyToClipboard text={attributesNFT['firstOwner']} onCopy={this.copied}>
                          <i className="icon fa fa-clone" aria-hidden="true"></i>
                        </CopyToClipboard>
                        {attributesNFT['firstOwner']?.substring(0, 15) + '.....' + attributesNFT['firstOwner']?.substring(35, attributesNFT['firstOwner'].length)}</li>
                    </ul>
                    <ul className="collection">
                      <li className="active">Collection</li>
                      <li> <i className="icon">{attributesNFT['purchased'] == "softtik" ? 'S' : 'C'}</i>
                        {attributesNFT['purchased'] == "softtik"
                          ? "Softtik"
                          : "Collection"
                        }
                      </li>
                    </ul>
                    <a href="#" className="sale-btn">10% of sales will go to creator</a>

                    <ul class="nav nav-tabs" id="keyTab" role="tablist">
                      <li class="nav-item" role="presentation">
                        <a class="nav-link active" id="bids-tab" data-toggle="tab" href="#bids" role="tab" aria-controls="bids" aria-selected="true">Bids</a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a class="nav-link" id="details-tab" data-toggle="tab" href="#details" role="tab" aria-controls="details" aria-selected="false">Details</a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a class="nav-link" id="history-tab" data-toggle="tab" href="#history" role="tab" aria-controls="history" aria-selected="false">History</a>
                      </li>
                    </ul>

                    <div class="tab-content" id="keyTabContent">
                      <div class="tab-pane fade show active" id="bids" role="tabpanel" aria-labelledby="bids-tab">
                        <div className="tab-inner">
                          <div className="icon">
                            <img src={require("../../static/images/landing/singlepage/2.jpg")} alt="" />
                          </div>
                          <div className="text-box">
                            <h4>0.13 wETH by 0xd749967cc...df93</h4>
                            <p className="time">14 hours ago</p>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="details" role="tabpanel" aria-labelledby="details-tab">
                        <div className="tab-inner">
                          <div className="icon">
                            <img src={require("../../static/images/landing/singlepage/2.jpg")} alt="" />
                          </div>
                          <div className="text-box">
                            <h4>0.13 wETH by 0xd749967cc...df93</h4>
                            <p className="time">14 hours ago</p>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="history" role="tabpanel" aria-labelledby="history-tab">
                        <div className="tab-inner">
                          <div className="icon">
                            <img src={require("../../static/images/landing/singlepage/2.jpg")} alt="" />
                          </div>
                          <div className="text-box">
                            <h4>0.13 wETH by 0xd749967cc...df93</h4>
                            <p className="time">14 hours ago</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="winning-area">
                    <div className="wining-bid-area">
                      <div className="wining-bid-inner">
                        <h3>Winning Bid</h3>
                        <div className="price-area">
                          <div className="icon">
                            <img src={require("../../static/images/landing/singlepage/2.jpg")} alt="" />
                          </div>
                          <div className="text-box">
                            <h4>0.13 wETH</h4>
                            <p className="price">~$305.53</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="coutdown-area">
                      <h3>This auction has ended</h3>
                      <div class="coutdown">
                        {attributesNFT['endingDate'] &&
                          <Countdown date={parseInt((1631069247) + Date.now()) / 1000 + parseInt(((1631069247) + Date.now()))} renderer={renderer} onComplete={this.handleReload} />
                        }
                      </div>
                    </div>
                  </div>
                  <button className="place-button">Buy 123456..</button>
                  <button className="place-button" onClick={this.toggleBidModal}>Place Bid</button> */}
                </div>
              </div>

              <div className="content-block col-lg-12 col-md-12">
                <div className="content-area">
                  <div className="contest-box">
                    <div className="inner-box">
                      <div className="heading-area">
                        <h3><i className="icon"><img src={require("../../static/images/landing/collection/ite-activity-icon.png")} alt="" /></i>Item activity</h3>
                      </div>
                      <div className="text-box" >
                        <div className="group-form">
                          <TextField
                            fullWidth
                            // value={name}
                            label="Filter"
                            margin="normal"
                            id="standard-name"
                          // onChange={this.handleChange('name')}
                          />
                          <i className="icon"><img src={require("../../static/images/landing/landingnft/category/down-cat.png")} alt="" /></i>
                        </div>
                        <div className="filter-area">
                          <button className="filter">Sales <i className="cancel"><img src={require("../../static/images/landing/collection/close.png")} alt="" /></i></button>
                          <button className="filter">Transfers <i className="cancel"><img src={require("../../static/images/landing/collection/close.png")} alt="" /></i></button>
                          <button className="clear-btn">Clear all</button>
                        </div>
                        <div className="listing-table table-responsive">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th>Event</th>
                                <th>Price</th>
                                <th>From</th>
                                <th>To</th>
                                <th>Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <p>Minted</p>
                                </td>
                                <td><p>-</p></td>
                                <td><p>Null address</p></td>

                                <td><p>D42174</p></td>
                                <td><p>10 days ago</p></td>
                              </tr>
                              <tr>
                                <td>
                                  <p>Minted</p>
                                </td>
                                <td><p>-</p></td>
                                <td><p>Null address</p></td>

                                <td><p>D42174</p></td>
                                <td><p>10 days ago</p></td>
                              </tr>
                              <tr>
                                <td>
                                  <p>Minted</p>
                                </td>
                                <td><p>-</p></td>
                                <td><p>Null address</p></td>

                                <td><p>D42174</p></td>
                                <td><p>10 days ago</p></td>
                              </tr>

                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="contest-box">
                    <div className="inner-box">
                      <div className="heading-area">
                        <h3><i className="icon"><img src={require("../../static/images/landing/collection/item-info-icon.png")} alt="" /></i>More from this collection</h3>
                      </div>
                      <div className="text-box" >
                        <div className="collection-box">
                          <div className="collection-nft-box">
                            <div className="collection-nft-inner">
                              <div className="nft-collection-area">
                                <div className="row clearfix">
                                  <div className="nft-box col-lg-3 col-md-6 col-sm-12">
                                    <div className="nft-box-inner">
                                      <div className="image-box">
                                        <img src={require("../../static/images/landing/collection/nft-1.png")} alt="" />
                                      </div>
                                      <div className="text-box">
                                        <div className="left-area">
                                          <h5>NFT bored bad bunny</h5>
                                          <h4>Bad Bored Bunny #7223</h4>
                                          <Link to="#" className="heart"><img src={require("../../static/images/landing/collection/heart.png")} alt="" /></Link>
                                        </div>
                                        <div className="right-area">
                                          <p>Top bid</p>
                                          <h3><i className="icon"><img src={require("../../static/images/landing/collection/eth.png")} alt="" /></i>0.0023</h3>
                                          <h6>5 days left</h6>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="nft-box col-lg-3 col-md-6 col-sm-12">
                                    <div className="nft-box-inner">
                                      <div className="image-box">
                                        <img src={require("../../static/images/landing/collection/nft-2.png")} alt="" />
                                      </div>
                                      <div className="text-box">
                                        <div className="left-area">
                                          <h5>NFT bored bad bunny</h5>
                                          <h4>Bad Bored Bunny #7223</h4>
                                          <Link to="#" className="heart"><img src={require("../../static/images/landing/collection/heart.png")} alt="" /></Link>
                                        </div>
                                        <div className="right-area">
                                          <p>Top bid</p>
                                          <h3><i className="icon"><img src={require("../../static/images/landing/collection/eth.png")} alt="" /></i>0.0023</h3>
                                          <h6>5 days left</h6>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="nft-box col-lg-3 col-md-6 col-sm-12">
                                    <div className="nft-box-inner">
                                      <div className="image-box">
                                        <img src={require("../../static/images/landing/collection/nft-3.png")} alt="" />
                                      </div>
                                      <div className="text-box">
                                        <div className="left-area">
                                          <h5>NFT bored bad bunny</h5>
                                          <h4>Bad Bored Bunny #7223</h4>
                                          <Link to="#" className="heart"><img src={require("../../static/images/landing/collection/heart.png")} alt="" /></Link>
                                        </div>
                                        <div className="right-area">
                                          <p>Top bid</p>
                                          <h3><i className="icon"><img src={require("../../static/images/landing/collection/eth.png")} alt="" /></i>0.0023</h3>
                                          <h6>5 days left</h6>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="nft-box col-lg-3 col-md-6 col-sm-12">
                                    <div className="nft-box-inner">
                                      <div className="image-box">
                                        <img src={require("../../static/images/landing/collection/nft-4.png")} alt="" />
                                      </div>
                                      <div className="text-box">
                                        <div className="left-area">
                                          <h5>NFT bored bad bunny</h5>
                                          <h4>Bad Bored Bunny #7223</h4>
                                          <Link to="#" className="heart"><img src={require("../../static/images/landing/collection/heart.png")} alt="" /></Link>
                                        </div>
                                        <div className="right-area">
                                          <p>Top bid</p>
                                          <h3><i className="icon"><img src={require("../../static/images/landing/collection/eth.png")} alt="" /></i>0.0023</h3>
                                          <h6>5 days left</h6>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


        {/* PLACE A BID MODAL */}
        {/* <Modal isOpen={isBidModal} toggle={this.toggleBidModal} className="place-bid">
          <ModalHeader toggle={this.toggleBidModal}>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>
          <ModalBody className="modal-body">
            <div className="placebid-inner">
              <h3>Place a bid</h3>
              <p>Your are about to place a bid for <b>{singleNFTData['name']}</b> by  <b>{attributesNFT['firstOwner']?.substring(0, 15) + '.....' + attributesNFT['firstOwner']?.substring(35, attributesNFT['firstOwner'].length)}</b></p>
              <div className="bidprice-box">
                <h4>Your Bid</h4>
                <ul className="price-bid">
                  <li>0.13651</li>
                  <input value={amount} onChange={(e) => this.setState({ amount: e.target.value })} placeholder="Bit Amount" />
                  <li className="price-total"><i className="icon">?</i> wETH</li>
                </ul>
              </div>
              <div className="total-bid-area">
                <h4>Must be at least 0.13651wETH</h4>
                <ul className="price-bid">
                  <li>Your bidding balance</li>
                  <li className="price-total">{amount || 0} wETH</li>

                  <li>Your blance</li>
                  <li className="price-total">{balance && parseFloat(balance).toFixed(2)} wETH</li>

                  <li>Service fee</li>
                  <li className="price-total">{fee} wETH</li>

                  <li>You will pay</li>
                  <li className="price-total">{amount ? (parseFloat(amount) + parseFloat(fee)) : 0} wETH</li>
                </ul>
              </div>
              <div className="bid-btn">
                <button onClick={this.handlePlaceBid}>Place a Bid</button>
              </div>
            </div>
          </ModalBody>
        </Modal> */}


        <Modal isOpen={false} toggle={this.toggleOfferModal} className="offer-modal">
          <ModalHeader toggle={this.toggleOfferModal}>

          </ModalHeader>
          <ModalBody className="modal-body">
            <div className="placebid-inner">
              <h3>Make an offer</h3>
              <div className="bidprice-box">
                <h4>Price</h4>
                <ul className="price-bid">
                  <li><i className="icon"><img src={require("../../static/images/landing/activity/blk-1.png")} alt="" /></i>wETH</li>
                  <input value={amount} onChange={(e) => this.setState({ amount: e.target.value })} placeholder="Amount" />
                  <li className="price-total"> $0.00</li>
                </ul>

              </div>
              <p>balance: 0.0000wETH</p>
              <div className="offer-section">
                <button type="button" className="time-dropdown">All time <i className='icon'><img src={require("../../static/images/landing/collection/down.png")} alt="" /></i></button>
                <TextField
                  fullWidth
                  // value={name}
                  label="Time"
                  margin="normal"
                  id="standard-name"
                // onChange={this.handleChange('name')}
                />
              </div>

              <div className="checkbox">
                <input type="checkbox" />
                <label>By checking this box, I agree Opensea's Terms of Service</label>
              </div>
              <div className="bid-btn text-center">
                <button className="btn-style-one" disabled>Make Offer</button>
                <button className="btn-style-one">Convert ETH</button>
              </div>
            </div>
          </ModalBody>
        </Modal>


        {/****** APPROVE BID MODAL ******/}
        {/* <Modal isOpen={isApproveBid} toggle={this.toggleApproveBidModal} className="follow-modal">
          <ModalHeader toggle={this.toggleApproveBidModal}>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>
          <ModalBody className="modal-body">
            <div className="follow-step">
              <h2>Follow steps</h2>
              <div className="follow-block active">
                <div className="icon-area">
                  <i className="fa fa-spinner fa-spin"></i>
                </div>
                <div className="text-box">
                  <h4>Mint</h4>
                  <p>Send transaction to create your NFT</p>
                </div>
              </div>
              <button className="done-btn">In progress..</button>
              <div className="follow-block">
                <div className="icon-area">
                  <i className="fa fa-check"></i>
                </div>
                <div className="text-box">
                  <h4>Approved</h4>
                  <p>This transaction in conducted only once per collection</p>
                </div>
              </div>
              <button className="start-btn style-two">Start</button>
              <div className="follow-block">
                <div className="icon-area">
                  <i className="fa fa-check"></i>
                </div>
                <div className="text-box">
                  <h4>Set fixed price</h4>
                  <p>Sign message to set fixed price</p>
                </div>
              </div>
              <button className="start-btn style-two">Start</button>
              <button className="cancel-btn">Cancel</button>
            </div>
          </ModalBody>
        </Modal> */}

        <Footer />
      </div>
    );
  }

}


const mapDispatchToProps = { getSingleNFT, setLoader };

const mapStateToProps = ({ NFT }) => {
  let { singleNFT } = NFT;
  return { singleNFT }
};


export default connect(mapStateToProps, mapDispatchToProps)(SingleNFT);