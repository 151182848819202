import React, { Component } from 'react';
import { HashLink } from 'react-router-hash-link';
import { Phone, Room, Email } from '@material-ui/icons'
import { Link } from 'react-router-dom';
import './index.css';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {};

    }

    render() {
        return (
            // <div className='footer-fot' style={{ backgroundImage: `url(${require("../../static/images/landing/landingnft/footer-bg.png")})` }}>
            //     <div className="container">
            //         <div className="row">
            //             <div className="col-lg-8 col-md-8 col-12  offset-lg-2 offset-md-2 offset-0 ">
            //                 <div className="footer-main">
            //                     <div className="footer-socials">
            //                         <div className="footer-socialss social-ones text-center">
            //                             <a href="" className="social-one"><span className=""><i className="fa fa-facebook-f" aria-hidden="true"></i></span></a>
            //                         </div>
            //                         <div className="footer-socialss social-twos">
            //                             <a href="" className="social-two"> <span className=""><i className="fa fa-twitter" aria-hidden="true"></i></span></a>
            //                         </div>
            //                         <div className="footer-socialss social-threes">
            //                             <a href="" className="social-three"><span className=""><i className="fa fa-linkedin" aria-hidden="true"></i></span></a>
            //                         </div>
            //                     </div>
            //                 </div>
            //                 <div className="menu-button">
            //                     <div className="menue-one">
            //                     <li><HashLink smooth to='/'>Home</HashLink></li>
            //                     <li><HashLink smooth to='/#ourbenefit'>Our Benefits</HashLink></li>
            //                     <li><HashLink smooth to='/#toptrending'>Top Trending</HashLink></li>
            //                     <li><HashLink smooth to='/#recent'>Recent Activity</HashLink></li>
            //                     <li><HashLink smooth to='/#contact'>Contact Us</HashLink></li>
            //                     </div>
            //                 </div>
            //                 <p>Copyrights © All Rights Reserved By</p>
            //             </div>
            //         </div>
            //     </div>

            // </div>
            <footer className="main-footer" role="img" aria-label="Footer backgroud">
                <div className="auto-container">
                    <div className="widgets-section">
                        <div className="row clearfix">
                            <div className="footer-column  col-lg-12 col-md-6 col-sm-12">
                                <div className="footer-widget about-widget text-center">
                                    <div className="widget-content">
                                        <p className="text">Copyright Softtik technologies</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;