import { connect } from 'react-redux';
import React, { Component } from 'react';

import './index.css';
import { css } from "@emotion/react";
import BounceLoader from "react-spinners/BounceLoader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class PageLoader extends Component {

  render() {
    const { setLoader } = this.props
    return (
      <>
        {/* {setLoader.status !== true && */}
        {setLoader.status == true &&
          <div className='page-loader'>
            <div className="loader-area">
              <BounceLoader
                css={override}
                size={50}
                color={'#2d2f3d'}
                loading={true}
              />
              <span className="loading-text mt-5">{setLoader.message}</span>
            </div>
          </div>
        }
      </>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = ({ NFT }) => {
  let { setLoader } = NFT;
  return { setLoader };
};

export default connect(mapStateToProps, mapDispatchToProps)(PageLoader);
