import EventBus from "eventing-bus";
import { connect } from "react-redux";
import React, { Component } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { TextField } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Send, CheckCircle, Info, Error, Done, Facebook, Twitter, Instagram, LinkedIn, LiveTvRounded } from '@material-ui/icons';

import './index.css';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { decodeIPFS } from '../../store/helper';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { getMyCollections, getBasicCollectionDetails } from '../../store/actions/NFT'
import { Redirect } from 'react-router'


class MyCollection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoadingBasicItems: true
    };
  };

  componentDidMount() {
    this.setState(
      this.props.getMyCollections(this.props.address)
    )

  }

  async componentWillReceiveProps({ setMyCollections, setBasicCollectionDetails }) {
    if (setMyCollections['collectionAddress']) {
      this.props.getBasicCollectionDetails(setMyCollections['collectionAddress']);
    }
    if (Object.keys(setBasicCollectionDetails).length !== 0) { this.setState({ isLoadingBasicItems: false }) }
  }

  copy = () => EventBus.publish("success", 'Wallet Address Copied');

  render() {

    let { address, setMyCollections, setBasicCollectionDetails, getBasicCollectionDetails } = this.props
    let { isLoadingBasicItems } = this.state
    console.log("************************setMyCollections", setMyCollections);
    if (!address) {
      return <Redirect to="/" />
    }

    return (
      <div className='profile-nft'>

        <Navbar />
        {/* Haeder Section */}
        <section className="mycollection-page" style={{ backgroundImage: `url(${require("../../static/images/landing/collection/mycollection-bg.png")})` }}>
          <div className="auto-container">
            <div className="row">
              <div className="col-12">
                <div className="banner-area">
                  <div className="sec-title">
                    <h2>My Collections</h2>
                    <p className="">Create, curate, and manage collections of unique NFT to share and sell. <i className="icon" data-toggle="tooltip" data-placement="top" title="Create, curate, and manage collections of unique NFT to share and sell."><img src={require("../../static/images/landing/collection/info-bg-icon.png")} alt="" /></i></p>
                  </div>
                  <div className="btn-group">
                    <button type="button" className="btn-style-one">Get Started <i className='icon fa fa-plus'></i>Create a collection</button>
                    <button type="button" className="right-btn"><i className="icon"><img src={require("../../static/images/landing/collection/dots.png")} alt="" /></i></button>
                  </div>
                </div>
              </div>
              {setMyCollections?.map(data =>
                <div className="collection-box col-lg-4 col-md-6 col-sm-12">
                  <div className="collection-inner">
                    <div className="banner-img">
                      <img src={data['uploadBanner']} height="100px" alt="" />
                    </div>
                    <div className="avatar-box">
                      <img src={data['uploadLogo']} width="80px" height="80px" alt="" />
                    </div>
                    <div className="text-box">
                      <h4><Link to={{
                        pathname: `/collection/${data['collectionName']}`,
                        state: {
                          collecionName: `${data['collectionName']}`
                        }
                      }}>{data['collectionName']}</Link></h4>
                      <p>by <Link to="#">{data['ownerAddress']===address?'You':''}</Link></p>
                      <Link to="#">{data?.['nfts'].length} items</Link>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
        <Footer />

      </div>
    );
  }

}

const mapDispatchToProps = { getMyCollections, getBasicCollectionDetails };

const mapStateToProps = ({ Auth, NFT }) => {
  let { address } = Auth;
  let { setMyCollections, setBasicCollectionDetails } = NFT;
  return { address, setMyCollections, setBasicCollectionDetails }
};


export default connect(mapStateToProps, mapDispatchToProps)(MyCollection);