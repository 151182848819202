const { env } = require('../config');
const { web3, web3Obj } = require('../web3');

const TokenData = require(`./${env}/Token.json`);
const MarketplaceData = require(`./${env}/Marketplace.json`);

const networks = {
  0: 'Disconnected',
  1: 'Mainnet',
  4: 'Rinkeby',
  42: 'Kovan',
}

const MarketplaceABI = MarketplaceData['abi'];
const MarketplaceAddress = MarketplaceData['address'];
const Marketplace = new web3.eth.Contract(MarketplaceABI, MarketplaceAddress);

const TokenABI = TokenData['abi'];
const TokenAddress = TokenData['address'];
const Token = new web3.eth.Contract(TokenABI, TokenAddress);

const Tokenbytecode = TokenData['bytecode'];

module.exports = {
  web3, web3Obj, Tokenbytecode, 
  Token, TokenABI, TokenAddress,
  Marketplace, MarketplaceABI, MarketplaceAddress,
};
