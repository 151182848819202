import EventBus from "eventing-bus";
import { connect } from "react-redux";
import React, { Component } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { TextField } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Send, CheckCircle, Info, Error, Done, Facebook, Twitter, Instagram, LinkedIn, LiveTvRounded } from '@material-ui/icons';
// import OurTeam from './ourTeam';
// import OwlCarousel from 'react-owl-carousel';

import './index.css';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { updateUserProfile, getUserData } from '../../store/actions/Auth'
import { Redirect } from 'react-router'


class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bio: '',
      file: '',
      email: '',
      imageNew: '',
      customurl: '',
      displayname: '',
      twitterName: '',
      isLoading: false,
      imageUploaded: '',
      selectedFile: null,
      personalProfile: '',
    };
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userprofileData !== this.props.userprofileData) {
      const { name, customeurl, email, bio, twitterName, portfolioLink, avatar } = this.props.userprofileData
      this.setState({ selectedFile: avatar, displayname: name, customurl: customeurl, twitterName: twitterName, email: email, bio: bio, personalProfile: portfolioLink, isLoading: false })
    }
  }

  copy = () => EventBus.publish("success", 'Wallet Address Copied');

  render() {
    const owl_option = {
      margin: 30,
      nav: false,
      loop: true,
      autoplay: true,
      responsive: {
        0: {
          items: 1,
        },
        400: {
          items: 1,
        },
        600: {
          items: 2,
        },
        700: {
          items: 3,
        },
        1000: {
          items: 4,
        }
      },
    };

    let { categories, types, userprofileData, address } = this.props
    let { displayname, customurl, bio, twitterName, personalProfile, email, selectedFile } = this.state

    if (!address) {
      return <Redirect to="/" />
    }

    return (
      <div className='profile-nft'>

        <Navbar />
        {/* Haeder Section */}
        <section className="header-section" style={{ backgroundImage: `url(${require("../../static/images/landing/landingnft/profile/banner-back1.png")})` }}>
        </section>

        <div className="first-section" style={{ backgroundImage: `url(${require("../../static/images/landing/landingnft/profile/profile-bg.png")})` }}>
          <div className="auto-container">
            <div className="tocaya">
              <div className="images-mid text-center">
                <img src={selectedFile ? selectedFile : require("../../static/images/landing/landingnft/profile/avtar3.png")} className="image-avatar" alt="" />
                <h3>Unnamed</h3>

                {/* <h5>{displayname} <span className="At">@{twitterName} | <span><img src={require("../../static/images/landing/landingnft/profile/global.png")} alt="" /></span > <span className="mail">{personalProfile}</span></span></h5> */}
                <CopyToClipboard text={address} onCopy={this.copy}>
                  <button className="button-address" type="button">{address}<span className="button-copy"><i class="fa fa-files-o" aria-hidden="true"></i></span></button>
                </CopyToClipboard>
                <p>Joined January, 2022</p>
                <div className="profile-setting-area">
                  <Link className="profile-area" type="button"><img src={require("../../static/images/landing/landingnft/profile/share-icon.png")} alt="" /></Link>
                  <Link className="profile-area" to="/editprofile" ><img src={require("../../static/images/landing/landingnft/profile/settings-icon.png")} alt="" /></Link>
                </div>

                <p>{bio}</p>
                <div className="row">
                  {/* <div className="buttonss d-flex justify-content-center">
                    <button className="button-one shadow" type="button">Follow</button>
                    <button className="button-two shadow" type="button"><img src={require("../../static/images/landing/landingnft/profile/export.png")} alt="" /></button>
                    <button className="button-three shadow" type="button"><img src={require("../../static/images/landing/landingnft/profile/three-dot.png")} alt="" /></button>
                    
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="tabs-accounts-cards">
            {/* <div className="card-tab-menu">
            <button type="button"><i class="icon fa fa-flash"></i> ON SALE</button>
            <button type="button"><i class="icon fa fa-trophy"></i> OWNED</button>
            <button type="button"><i class="icon fa fa-hourglass-start"></i> CREATED</button>
            <button type="button"><i class="icon fa fa-heart"></i> LIKED</button>
            <button type="button"><i class="icon fa fa-tasks"></i> ATIVITY</button>
            <button type="button"><i class="icon fa fa-users"></i> FOLLOWING</button>
            <button type="button"><i class="icon fa fa-user-plus"></i> FOLLOWERS</button>
          </div> */}
            {/* <button className="openmodal btn-style-one" onClick={this.toggleBuyWallet}>Open Modal</button> */}

            <div className="auto-container">
              <div className="row">
                <div className="col-12">
                  <div className="card-tab-menu">

                    <button type="button" className="active">Collected<i class="icon fa fa-image"></i></button>
                    <button type="button">Created<i class="icon fa fa-edit"></i></button>
                    <button type="button">Favourited<i class="icon fa fa-heart"></i></button>
                    <button type="button">Hidden<i class="icon fa fa-eye-slash"></i></button>
                    <button type="button">Activity<i class="icon fa fa-stumbleupon"></i></button>
                    <button type="button">Offers<i class="icon fa fa-tag"></i></button>
                    <button type="button">Listings<i class="icon fa fa-table"></i></button>

                  </div>
                </div>
              </div>
            </div>

          </div>

          <div className=" first-sectionss">
            <div className="auto-container">
              <div className="row">
                <div className="col-12">
                  <div className="search-area row">
                    <div className="group-form search-form col-lg-4 col-md-6 col-sm-12">
                      <TextField
                        fullWidth
                        // value={name}
                        label="Search"
                        margin="normal"
                        id="standard-name"
                      // onChange={this.handleChange('name')}
                      />
                    </div>
                    <div className="group-form single-form col-lg-3 col-md-6 col-sm-12">
                      <TextField
                        fullWidth
                        // value={name}
                        label="Single items"
                        margin="normal"
                        id="standard-name"
                      // onChange={this.handleChange('name')}
                      />
                      <i className="icon"><img src={require("../../static/images/landing/landingnft/category/down-cat.png")} alt="" /></i>
                    </div>
                    <div className="group-form single-form col-lg-3 col-md-6 col-sm-12">
                      <TextField
                        fullWidth
                        // value={name}
                        label="Recently received"
                        margin="normal"
                        id="standard-name"
                      // onChange={this.handleChange('name')}
                      />
                      <i className="icon"><img src={require("../../static/images/landing/landingnft/category/down-cat.png")} alt="" /></i>
                    </div>
                    <div className="group-form button-form col-lg-2 col-md-6 col-sm-12">
                      <button className="expend-button"><img src={require("../../static/images/landing/landingnft/category/expend.png")} alt="" /></button>
                      <button className="expend-button"><img src={require("../../static/images/landing/landingnft/category/expend-2.png")} alt="" /></button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-12">
                  <div className="card">
                    <div className="upper-divs-triple">
                      <div className="left-side">
                        <img src={require("../../static/images/landing/landingnft/category/mercedes.png")} alt="" />
                        <img src={require("../../static/images/landing/landingnft/category/menn.png")} className="margin-ll" alt="" />
                        <img src={require("../../static/images/landing/landingnft/category/checktick.png")} className="margin-lll" alt="" />
                      </div>
                      <div className="right-side">
                        <img src={require("../../static/images/landing/landingnft/category/dot-nft.png")} alt="" />
                      </div>
                    </div>
                    <img src={require("../../static/images/landing/landingnft/category/girl.png")} className="div-image-card" alt="" />
                    <div className="down-trending">
                      <div className="coutdown-time">
                        <ul className="timer">
                          <li>99</li>
                          <li>:</li>
                          <li>23</li>
                          <li>:</li>
                          <li>09</li>
                          <li>:</li>
                          <li>51</li>
                        </ul>
                        <div className="right-side">
                          <p>left</p>
                          <i className="icon"><img src={require("../../static/images/landing/landingnft/category/fire.png")} className="icon" alt="" /></i>
                        </div>

                      </div>
                      <h4>Trending Collections</h4>
                      <div className="outer-main-content">
                        <div className="left-watchlist">
                          <p className="">watchlist.eth</p>
                          <p className="">Minimum bid 1/1</p>
                          <p className="orange-color">wETH</p>
                        </div>
                        <div className="heart text-center">
                          <p>976</p>
                          {/* empty Icon */}
                          <img src={require("../../static/images/landing/landingnft/category/unlikeHeart.png")} alt="" />
                          {/* Fill Icon */}
                          {/* <img src={require("../../static/images/landing/landingnft/category/likeHeart.png")} alt="" /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-12">
                  <div className="card">
                    <div className="upper-divs-triple">
                      <div className="left-side">
                        <img src={require("../../static/images/landing/landingnft/category/mercedes.png")} alt="" />
                        <img src={require("../../static/images/landing/landingnft/category/menn.png")} className="margin-ll" alt="" />
                        <img src={require("../../static/images/landing/landingnft/category/checktick.png")} className="margin-lll" alt="" />
                      </div>
                      <div className="right-side">
                        <img src={require("../../static/images/landing/landingnft/category/dot-nft.png")} alt="" />
                      </div>
                    </div>
                    <img src={require("../../static/images/landing/landingnft/category/girl.png")} className="div-image-card" alt="" />
                    <div className="down-trending">
                      <h4>Trending Collections</h4>
                      <div className="outer-main-content">
                        <div className="left-watchlist">
                          <p className="">watchlist.eth</p>
                          <p className="">Minimum bid 1/1</p>
                          <p className="orange-color">wETH</p>
                        </div>
                        <div className="heart text-center">
                          <p>976</p>
                          {/* empty Icon */}
                          {/* <img src={require("../../static/images/landing/landingnft/category/unlikeHeart.png")} alt="" /> */}
                          {/* Fill Icon */}
                          <img src={require("../../static/images/landing/landingnft/category/likeHeart.png")} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-12">
                  <div className="card">
                    <div className="upper-divs-triple">
                      <div className="left-side">
                        <img src={require("../../static/images/landing/landingnft/category/mercedes.png")} alt="" />
                        <img src={require("../../static/images/landing/landingnft/category/menn.png")} className="margin-ll" alt="" />
                        <img src={require("../../static/images/landing/landingnft/category/checktick.png")} className="margin-lll" alt="" />
                      </div>
                      <div className="right-side">
                        <img src={require("../../static/images/landing/landingnft/category/dot-nft.png")} alt="" />
                      </div>
                    </div>
                    <img src={require("../../static/images/landing/landingnft/category/girl.png")} className="div-image-card" alt="" />
                    <div className="down-trending">
                      <h4>Trending Collections</h4>
                      <div className="outer-main-content">
                        <div className="left-watchlist">
                          <p className="">watchlist.eth</p>
                          <p className="">Minimum bid 1/1</p>
                          <p className="orange-color">wETH</p>
                        </div>
                        <div className="heart text-center">
                          <p>976</p>
                          {/* empty Icon */}
                          {/* <img src={require("../../static/images/landing/landingnft/category/unlikeHeart.png")} alt="" /> */}
                          {/* Fill Icon */}
                          <img src={require("../../static/images/landing/landingnft/category/likeHeart.png")} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-12">
                  <div className="card">
                    <div className="upper-divs-triple">
                      <div className="left-side">
                        <img src={require("../../static/images/landing/landingnft/category/mercedes.png")} alt="" />
                        <img src={require("../../static/images/landing/landingnft/category/menn.png")} className="margin-ll" alt="" />
                        <img src={require("../../static/images/landing/landingnft/category/checktick.png")} className="margin-lll" alt="" />
                      </div>
                      <div className="right-side">
                        <img src={require("../../static/images/landing/landingnft/category/dot-nft.png")} alt="" />
                      </div>
                    </div>
                    <img src={require("../../static/images/landing/landingnft/category/girl.png")} className="div-image-card" alt="" />
                    <div className="down-trending">
                      <h4>Trending Collections</h4>
                      <div className="outer-main-content">
                        <div className="left-watchlist">
                          <p className="">watchlist.eth</p>
                          <p className="">Minimum bid 1/1</p>
                          <p className="orange-color">wETH</p>
                        </div>
                        <div className="heart text-center">
                          <p>976</p>
                          {/* empty Icon */}
                          {/* <img src={require("../../static/images/landing/landingnft/category/unlikeHeart.png")} alt="" /> */}
                          {/* Fill Icon */}
                          <img src={require("../../static/images/landing/landingnft/category/likeHeart.png")} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-12">
                  <div className="card">
                    <div className="upper-divs-triple">
                      <div className="left-side">
                        <img src={require("../../static/images/landing/landingnft/category/mercedes.png")} alt="" />
                        <img src={require("../../static/images/landing/landingnft/category/menn.png")} className="margin-ll" alt="" />
                        <img src={require("../../static/images/landing/landingnft/category/checktick.png")} className="margin-lll" alt="" />
                      </div>
                      <div className="right-side">
                        <img src={require("../../static/images/landing/landingnft/category/dot-nft.png")} alt="" />
                      </div>
                    </div>
                    <img src={require("../../static/images/landing/landingnft/category/girl.png")} className="div-image-card" alt="" />
                    <div className="down-trending">
                      <h4>Trending Collections</h4>
                      <div className="outer-main-content">
                        <div className="left-watchlist">
                          <p className="">watchlist.eth</p>
                          <p className="">Minimum bid 1/1</p>
                          <p className="orange-color">wETH</p>
                        </div>
                        <div className="heart text-center">
                          <p>976</p>
                          {/* empty Icon */}
                          {/* <img src={require("../../static/images/landing/landingnft/category/unlikeHeart.png")} alt="" /> */}
                          {/* Fill Icon */}
                          <img src={require("../../static/images/landing/landingnft/category/likeHeart.png")} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-12">
                  <div className="card">
                    <div className="upper-divs-triple">
                      <div className="left-side">
                        <img src={require("../../static/images/landing/landingnft/category/mercedes.png")} alt="" />
                        <img src={require("../../static/images/landing/landingnft/category/menn.png")} className="margin-ll" alt="" />
                        <img src={require("../../static/images/landing/landingnft/category/checktick.png")} className="margin-lll" alt="" />
                      </div>
                      <div className="right-side">
                        <img src={require("../../static/images/landing/landingnft/category/dot-nft.png")} alt="" />
                      </div>
                    </div>
                    <img src={require("../../static/images/landing/landingnft/category/girl.png")} className="div-image-card" alt="" />
                    <div className="down-trending">
                      <h4>Trending Collections</h4>
                      <div className="outer-main-content">
                        <div className="left-watchlist">
                          <p className="">watchlist.eth</p>
                          <p className="">Minimum bid 1/1</p>
                          <p className="orange-color">wETH</p>
                        </div>
                        <div className="heart text-center">
                          <p>976</p>
                          {/* empty Icon */}
                          {/* <img src={require("../../static/images/landing/landingnft/category/unlikeHeart.png")} alt="" /> */}
                          {/* Fill Icon */}
                          <img src={require("../../static/images/landing/landingnft/category/likeHeart.png")} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-12">
                  <div className="card">
                    <div className="upper-divs-triple">
                      <div className="left-side">
                        <img src={require("../../static/images/landing/landingnft/category/mercedes.png")} alt="" />
                        <img src={require("../../static/images/landing/landingnft/category/menn.png")} className="margin-ll" alt="" />
                        <img src={require("../../static/images/landing/landingnft/category/checktick.png")} className="margin-lll" alt="" />
                      </div>
                      <div className="right-side">
                        <img src={require("../../static/images/landing/landingnft/category/dot-nft.png")} alt="" />
                      </div>
                    </div>
                    <img src={require("../../static/images/landing/landingnft/category/girl.png")} className="div-image-card" alt="" />
                    <div className="down-trending">
                      <h4>Trending Collections</h4>
                      <div className="outer-main-content">
                        <div className="left-watchlist">
                          <p className="">watchlist.eth</p>
                          <p className="">Minimum bid 1/1</p>
                          <p className="orange-color">wETH</p>
                        </div>
                        <div className="heart text-center">
                          <p>976</p>
                          {/* empty Icon */}
                          {/* <img src={require("../../static/images/landing/landingnft/category/unlikeHeart.png")} alt="" /> */}
                          {/* Fill Icon */}
                          <img src={require("../../static/images/landing/landingnft/category/likeHeart.png")} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-12">
                  <div className="card">
                    <div className="upper-divs-triple">
                      <div className="left-side">
                        <img src={require("../../static/images/landing/landingnft/category/mercedes.png")} alt="" />
                        <img src={require("../../static/images/landing/landingnft/category/menn.png")} className="margin-ll" alt="" />
                        <img src={require("../../static/images/landing/landingnft/category/checktick.png")} className="margin-lll" alt="" />
                      </div>
                      <div className="right-side">
                        <img src={require("../../static/images/landing/landingnft/category/dot-nft.png")} alt="" />
                      </div>
                    </div>
                    <img src={require("../../static/images/landing/landingnft/category/girl.png")} className="div-image-card" alt="" />
                    <div className="down-trending">
                      <h4>Trending Collections</h4>
                      <div className="outer-main-content">
                        <div className="left-watchlist">
                          <p className="">watchlist.eth</p>
                          <p className="">Minimum bid 1/1</p>
                          <p className="orange-color">wETH</p>
                        </div>
                        <div className="heart text-center">
                          <p>976</p>
                          {/* empty Icon */}
                          {/* <img src={require("../../static/images/landing/landingnft/category/unlikeHeart.png")} alt="" /> */}
                          {/* Fill Icon */}
                          <img src={require("../../static/images/landing/landingnft/category/likeHeart.png")} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>


        <Footer />


        <Modal isOpen={false} toggle={this.props.toggleBuyWallet} className="register-modal buy-token-modal">
          <div class="icon-area">
            <h1>6 <br></br><span>Days Left</span></h1>
          </div>
          <ModalHeader toggle={this.props.toggleBuyWallet}>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>
          <ModalBody className="modal-body">
            <div className="text-head-main">
              <h1>JOIN AND WIN</h1>
              <h4>Featured Lottery</h4>
              <h6>What is Featured Lottery?</h6>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus facilisis at dui et mollis. Quisque metus lacus, vehicula id gravida ut, pharetra id ex. Maecenas ipsum lorem, imperdiet non egestas a, aliquam a est. Proin gravida neque at nunc egestas, vitae scelerisque eros ullamcorper. Phasellus congue nulla tellus, sit amet molestie velit lacinia in. Etiam malesuada placerat nibh</p>
            </div>
          </ModalBody>
        </Modal>

      </div>
    );
  }

}

const mapDispatchToProps = { updateUserProfile, getUserData };

const mapStateToProps = ({ Auth }) => {

  let { isLogin, isSignup, address, isPolicyModal, loginToken, setAddress, UpdatedProfile, userprofileData } = Auth;
  return { isLogin, isSignup, address, isPolicyModal, UpdatedProfile, userprofileData }
};


export default connect(mapStateToProps, mapDispatchToProps)(Profile);