import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { TextField } from "@material-ui/core";
import React, { Component, Fragment } from "react";

import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import { web3 } from '../../store/contract'
import "./index.css";
import {
  getCollectionData,
  selectCollection,
  getCategoryNFT,
  toggleHeart,
  getNFTData,
  getNFTDataMarketplace,
  getLikedNfts,
  getAllCollections,
} from "../../store/actions/NFT";

class Category extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Active: null,
      currentItems: 8,
      allNFTArray: [],
      nftSoldData: [],
      nftBidData: [],
      activity: "LIST",
    };

    props.getNFTData('LIST');

    props.getNFTDataMarketplace('SELL');

    props.getCategoryNFT("all");

    props.getLikedNfts("LIKED");

    props.getAllCollections("COLLECTIONS");
  }

  componentWillReceiveProps({ types, allNFTData, nftDataMarketplace, nftData, collectionsMarketplace, likedNftMarketplace }) {
    if (types.length > 0) this.setState({ allNFTArray: allNFTData });
    let { activity, nftSoldData, nftBidData } = this.state;


    if (nftDataMarketplace && activity == "SELL") {
      let nftSoldArray = nftDataMarketplace['BidAccepted'].concat(nftDataMarketplace['OrderSuccessful']);
      for (let i = 0; i < nftSoldArray.length; i++) {
        nftSoldData.push(nftSoldArray[i]);
      }
    }
    if (nftDataMarketplace && activity == "BID") {
      for (let i = 0; i < nftDataMarketplace['BidCreated'].length; i++) {
        nftBidData.push(nftDataMarketplace['BidCreated'][i]);
      }
    }
    this.setState({ nftData, nftBidData, nftSoldData, collectionsMarketplace, likedNftMarketplace });
  }

  // selectCollection = (value) => {
  //   this.setState({ currentItems: 8 });
  //   this.setState({ Active: value.index });
  //   this.props.selectCollection(value.id);
  // };

  // getCategoryNFT = (value) => {
  //   this.setState({ currentItems: 8 });
  //   this.setState({ Active: value.index });
  //   this.props.getCategoryNFT(value.id);
  // };

  // getCollectionData = (value) => {
  //   this.setState({ currentItems: 8 });
  //   this.setState({ Active: value.index });
  //   this.props.getCollectionData(value.id);
  // };

  // LoadMoreItems = () => {
  //   this.setState({ currentItems: this.state.currentItems + 8 });
  // };

  // handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

  handleFilter = (activity) => {
    if (activity == 'LIST') this.props.getNFTData(activity);
    if (activity == 'BURN') this.props.getNFTData(activity);
    if (activity == 'TRANSFER') this.props.getNFTData(activity);
    if (activity == 'LIKED') this.props.getLikedNfts(activity);
    if (activity == 'BID') this.props.getNFTDataMarketplace(activity);
    if (activity == 'SELL') this.props.getNFTDataMarketplace(activity);
    if (activity == 'COLLECTIONS') this.props.getAllCollections(activity);
    this.setState({ activity, nftSoldData: [], nftBidData: [] });
  };

  render() {
    let { userprofileData, nftData, likedNftMarketplace, collectionsMarketplace } = this.props;
    let { currentItems, allNFTArray, activity, nftBidData, nftSoldData } = this.state;
    return (
      <div className="category-nft">
        <Navbar />
        <section className="catgery-sec">
          <div className="auto-container">
            <div className="row">
              <div className="col-12">
                <div className="category-btn">
                  <div className="sec-title">
                    <h2>Explore</h2>
                  </div>
                  <ul className="list-category">
                    <li>
                      <button className="catgory-link">Trending</button>
                    </li>
                    <li>
                      <button className="catgory-link">Top</button>
                    </li>
                    <li>
                      <button className="catgory-link">Art</button>
                    </li>
                    <li>
                      <button className="catgory-link">Collectibles</button>
                    </li>
                    <li>
                      <button className="catgory-link">Domain Names</button>
                    </li>
                    <li>
                      <button className="catgory-link">Music</button>
                    </li>
                    <li>
                      <button className="catgory-link">Photography</button>
                    </li>
                    <li>
                      <button className="catgory-link">Sports</button>
                    </li>
                    <li>
                      <button className="catgory-link">Trading Cards</button>
                    </li>
                    <li>
                      <button className="catgory-link">Utility</button>
                    </li>
                    <li>
                      <button className="catgory-link">Virtual Worlds</button>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-7 col-md-12">
                <div class="card">
                  <div class="card-header" id="headingThree">

                  </div>
                  <div id="collapseThree" class="collapse show" aria-labelledby="headingThree" data-parent="#accordion">
                    <div class="card-body">
                      <div className="explore">
                        <div className="auto-container">
                          <div className="row new-row">
                         
                            {activity === "LIKED" ?
                              activity === "LIKED" &&
                              <Fragment>
                                {likedNftMarketplace.length > 0 && likedNftMarketplace.map(data =>
                                  <div className="col-lg-6 col-md-4 col-12">
                                    <div className="card">
                                      <div className="upper-divs-triple">
                                        <div className="left-side">
                                          <img src={require("../../static/images/landing/landingnft/category/checktick.png")} className="margin-lll" alt="" />
                                        </div>
                                        <div className="right-side">
                                          <img src={require("../../static/images/landing/landingnft/category/dot-nft.png")} alt="" />
                                        </div>
                                      </div>
                                      <img src={`${data.nftUri}`} className="div-image-card" alt="" />
                                      <div className="down-trending">
                                        <div className="outer-main-content">
                                          <div className="left-watchlist">
                                            <b>{`${data.category}`}</b>
                                            <p className="">Likes: {`${data.totalLikes}`}</p>
                                          </div>
                                          <div className="heart text-center">
                                            <img src={require("../../static/images/landing/landingnft/category/likeHeart.png")} alt="" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Fragment>
                              :
                              <table class="table">
                                <thead>
                                  <tr>
                                    {/* <th className="item">Item</th> */}
                                    <th className="item">Event</th>
                                    <th>Price</th>
                                    <th>Quantity</th>
                                    <th>From</th>
                                    <th>To</th>
                                  </tr>
                                </thead>
                                <tbody>

                                  {activity === "SELL" &&
                                    <Fragment>
                                      {nftSoldData.length > 0
                                        ? nftSoldData.map(data => {
                                          return (
                                            <tr>
                                              <td>
                                                <h4><a href="#"><p>{data?.event}</p></a></h4>
                                              </td>
                                              <td>
                                                <h4><a href="#"><p>{web3.utils.fromWei(data?.returnValues?.biddingPrice, 'ether')}</p></a></h4>
                                              </td>
                                              <td>
                                                <h4><a href="#"><p>1</p></a></h4>
                                              </td>
                                              <td>
                                                <h4><a href="#"><p>{data?.returnValues?.bidder.slice(0, 8)}...</p></a></h4>
                                              </td>
                                              <td>
                                                <h4><a href="#"><p>{data?.returnValues[3].slice(0, 8)}...</p></a></h4>
                                              </td>
                                            </tr>
                                          )
                                        })
                                        : <tr><p>No Data Founded!!!</p></tr>
                                      }
                                    </Fragment>
                                  }

                                  {activity === "BID" &&
                                    <Fragment>
                                      {nftBidData.length > 0
                                        ? nftBidData.map(data => {
                                          return (
                                            <tr>
                                              <td>
                                                <h4><a href="#">{data['event']}</a></h4>
                                              </td>
                                              <td>
                                                <p className="price">
                                                  <i className="icon">
                                                    <img
                                                      src={require("../../static/images/landing/activity/eth-1.png")}
                                                      alt=""
                                                    />
                                                  </i>{" "}
                                                  {web3.utils.fromWei(data?.returnValues?.biddingPrice, 'ether')}
                                                </p>
                                              </td>
                                              <td>
                                                <p>1</p>
                                              </td>
                                              <td>
                                                <p className="meta-name">{data['returnValues']['bidder'].slice(9, 18)}...</p>
                                              </td>
                                              <td>
                                                <p>---</p>
                                              </td>
                                            </tr>
                                          )
                                        }
                                        )
                                        : <tr><p>No Data Founded!!!</p></tr>
                                      }
                                    </Fragment>
                                  }

                                  {activity === "LIST" &&
                                    <Fragment>
                                      {nftData.length > 0 ? nftData.map(data => {
                                        return (
                                          <tr>
                                            <td>
                                              <h4><a href="#">{data['type'] || data['@type']}</a></h4>
                                            </td>
                                            <td>
                                              <p className="price">
                                                <i className="icon">
                                                  <img
                                                    src={require("../../static/images/landing/activity/eth-1.png")}
                                                    alt=""
                                                  />
                                                </i>{" "}
                                                {data['price']?.slice(0, 4) ? data['price']?.slice(0, 4) : "--"}
                                                {/* <span>${parseFloat(data['priceUsd']).toFixed(2)}</span> */}
                                              </p>
                                            </td>
                                            <td>
                                              <p>{data['@type'] == "LIST" ? data?.make?.value : data?.take?.value || data?.nft?.value || data?.value}</p>
                                              {/* <p>{data?.make?.value || data?.nft?.value ? data?.take?.value : data?.value}</p> */}
                                              {/* <p>{data?.nft?.value ? data.nft.value ? data.nft.value :data.take.value : data.value}</p> */}
                                            </td>
                                            <td>
                                              <p className="meta-name">{data['@type'] == "BURN" ? data['owner']?.slice(9, 18) : data['seller'] ? data['seller']?.slice(9, 18) : data['from'] ? data['from'].slice(9, 18) : data['maker'].slice(9, 18)}...</p>
                                            </td>
                                            <td>
                                              <p>{data['@type'] == "BURN" ? "..." : data['buyer']?.slice(9, 18) || data['owner']?.slice(9, 18)}...</p>
                                            </td>
                                          </tr>
                                        )
                                      }
                                      ) : <tr><p>No Data Founded!!!</p></tr>}
                                    </Fragment>
                                  }

                                  {activity === "BURN" &&
                                    <Fragment>
                                      {nftData.length > 0 ? nftData.map(data => {
                                        return (
                                          <tr>
                                            <td>
                                              <h4><a href="#">{data['type'] || data['@type']}</a></h4>
                                            </td>
                                            <td>
                                              <p className="price">
                                                <i className="icon">
                                                  <img
                                                    src={require("../../static/images/landing/activity/eth-1.png")}
                                                    alt=""
                                                  />
                                                </i>{" "}
                                                {data['price']?.slice(0, 4) ? data['price']?.slice(0, 4) : "--"}
                                                {/* <span>${parseFloat(data['priceUsd']).toFixed(2)}</span> */}
                                              </p>
                                            </td>
                                            <td>
                                              <p>{data['@type'] == "LIST" ? data?.make?.value : data?.take?.value || data?.nft?.value || data?.value}</p>
                                              {/* <p>{data?.make?.value || data?.nft?.value ? data?.take?.value : data?.value}</p> */}
                                              {/* <p>{data?.nft?.value ? data.nft.value ? data.nft.value :data.take.value : data.value}</p> */}
                                            </td>
                                            <td>
                                              <p className="meta-name">{data['@type'] == "BURN" ? data['owner']?.slice(9, 18) : data['seller'] ? data['seller']?.slice(9, 18) : data['from'] ? data['from'].slice(9, 18) : data['maker'].slice(9, 18)}...</p>
                                            </td>
                                            <td>
                                              <p>{data['@type'] == "BURN" ? "..." : data['buyer']?.slice(9, 18) || data['owner']?.slice(9, 18)}...</p>
                                            </td>
                                          </tr>
                                        )
                                      }
                                      ) : <tr><p>No Data Founded!!!</p></tr>}
                                    </Fragment>
                                  }

                                  {activity === "TRANSFER" &&
                                    <Fragment>
                                      {nftData.length > 0 ? nftData.map(data => {
                                        return (
                                          <tr>
                                            <td>
                                              <h4><a href="#">{data['type'] || data['@type']}</a></h4>
                                            </td>
                                            <td>
                                              <p className="price">
                                                <i className="icon">
                                                  <img
                                                    src={require("../../static/images/landing/activity/eth-1.png")}
                                                    alt=""
                                                  />
                                                </i>{" "}
                                                {data['price']?.slice(0, 4) ? data['price']?.slice(0, 4) : "--"}
                                                {/* <span>${parseFloat(data['priceUsd']).toFixed(2)}</span> */}
                                              </p>
                                            </td>
                                            <td>
                                              <p>{data['@type'] == "LIST" ? data?.make?.value : data?.take?.value || data?.nft?.value || data?.value}</p>
                                              {/* <p>{data?.make?.value || data?.nft?.value ? data?.take?.value : data?.value}</p> */}
                                              {/* <p>{data?.nft?.value ? data.nft.value ? data.nft.value :data.take.value : data.value}</p> */}
                                            </td>
                                            <td>
                                              <p className="meta-name">{data['@type'] == "BURN" ? data['owner']?.slice(9, 18) : data['seller'] ? data['seller']?.slice(9, 18) : data['from'] ? data['from'].slice(9, 18) : data['maker'].slice(9, 18)}...</p>
                                            </td>
                                            <td>
                                              <p>{data['@type'] == "BURN" ? "..." : data['buyer']?.slice(9, 18) || data['owner']?.slice(9, 18)}...</p>
                                            </td>
                                          </tr>
                                        )
                                      }
                                      ) : <tr><p>No Data Founded!!!</p></tr>}
                                    </Fragment>
                                  }

                                </tbody>
                              </table>
                            }

                            <div className="button-loadmore">
                              {allNFTArray.length > currentItems
                                ? <button className="loadmore" onClick={() => this.LoadMoreItems()}>LOAD MORE</button>
                                : <button className="loadmore">No More Items</button>
                              }
                            </div>
                          </div>
                        </div>
                      </div >
                    </div>
                  </div>
                </div>
                <div id="accordion">

                </div>
              </div>
              <div className="buttons-block col-lg-5 col-md-12">
                <div className="buttons-inner">
                  <div className="filers-area">
                    <h5>Filters</h5>
                    <a className="reset-filters" >
                      <button onClick={() => this.handleFilter("LIST")}>
                        <a className="reset-filters">Reset Filter</a>
                      </button>
                    </a>
                  </div>
                  <div id="accordion">
                    <div class="card">
                      <div class="card-header" id="headingSix">
                        <h5 class="mb-0">
                          <button
                            class="red btn btn-link"
                            data-toggle="collapse"
                            data-target="#collapseSix"
                            aria-expanded="false"
                            aria-controls="collapseSix"
                          >
                            Event Type
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapseSix"
                        class="collapse show"
                        aria-labelledby="headingSix"
                        data-parent="#accordion"
                      >
                        <div class="card-body">
                          <ul className="filter-list">
                            <li
                              className={`listings ${activity == "LIST" && "active"
                                }`}
                            >
                              <button onClick={() => this.handleFilter("LIST")}>
                                <i className="icon fa fa-tag"></i> Listings
                              </button>
                            </li>
                            <li
                              className={`purchase ${activity == "SELL" && "active"
                                }`}
                            >
                              <button onClick={() => this.handleFilter("SELL")}>
                                <i className="icon fa fa-dollar"></i>Sold{" "}
                              </button>
                            </li>
                            <li
                              className={`transfer ${activity == "TRANSFER" && "active"
                                }`}
                            >
                              <button
                                onClick={() => this.handleFilter("TRANSFER")}
                              >
                                <i className="icon fa fa-exchange"></i> Transfer
                              </button>
                            </li>
                            <li
                              className={`burns ${activity == "BURN" && "active"
                                }`}
                            >
                              <button onClick={() => this.handleFilter("BURN")}>
                                <i className="icon fa fa-fire"></i> Burns
                              </button>
                            </li>
                            <li
                              className={`liked ${activity == "LIKED" && "active"
                                }`}
                            >
                              <button
                                onClick={() => this.handleFilter("LIKED")}
                              >
                                <i className="icon fa fa-heart"></i> Likes
                              </button>
                            </li>
                            <li
                              className={`bids ${activity == "BID" && "active"
                                }`}
                            >
                              <button onClick={() => this.handleFilter("BID")}>
                                <i className="icon fa fa-gavel"></i> Bids
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div class="card-header" id="headingSeven">
                        <h5 class="mb-0">
                          <div className={`collections ${activity == "COLLECTIONS" && "active"}`}>
                            <button
                              class="white btn btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#collapseSeven"
                              aria-expanded="false"
                              aria-controls="collapseSeven"
                              onClick={() => this.handleFilter("COLLECTIONS")}
                            >
                              Collections
                            </button>
                          </div>
                        </h5>
                      </div>
                      <div
                        id="collapseSeven"
                        class="collapse"
                        aria-labelledby="headingSeven"
                        data-parent="#accordion"
                      >
                        <div class="card-body">
                          {activity === "COLLECTIONS" &&
                            <Fragment>
                              {collectionsMarketplace.length > 0
                                ? collectionsMarketplace.map(data => {
                                  return (
                                    <div className="fitter-search">
                                      <div className="collections-box">
                                        <div className="collections-block">
                                          <div className="img-box">
                                            <img
                                              src={`${data.uploadLogo}`}
                                              alt=""
                                              width="50"
                                              height="50"
                                            />
                                          </div>
                                          <div className="text-box">
                                            <h4>{`${data.collectionName}`}</h4>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                  )
                                })
                                : <tr><p>No Data Founded!!!</p></tr>
                              }
                            </Fragment>
                          }
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div class="card-header" id="headingEight">
                        <h5 class="mb-0">
                          <button
                            class="blue btn btn-link collapsed"
                            data-toggle="collapse"
                            data-target="#collapseEight"
                            aria-expanded="false"
                            aria-controls="collapseEight"
                          >
                            Chains
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapseEight"
                        class="collapse"
                        aria-labelledby="headingEight"
                        data-parent="#accordion"
                      >
                        <div class="card-body">
                          <div className="collections-box style-two">
                            <div className="collections-block">
                              <div className="img-box">
                                <img
                                  src={require("../../static/images/landing/activity/eth-1.png")}
                                  alt=""
                                />
                              </div>
                              <div className="text-box">
                                <h4>Etherum</h4>
                              </div>
                            </div>

                            <div className="collections-block">
                              <div className="img-box">
                                <img
                                  src={require("../../static/images/landing/landingnft/category/polygen.png")}
                                  alt=""
                                />
                              </div>
                              <div className="text-box">
                                <h4>Polygon</h4>
                              </div>
                            </div>

                            <div className="collections-block">
                              <div className="img-box">
                                <img
                                  src={require("../../static/images/landing/landingnft/category/klaytn.png")}
                                  alt=""
                                />
                              </div>
                              <div className="text-box">
                                <h4>Tezos</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}

const mapDispatchToProps = {
  getCollectionData,
  selectCollection,
  getCategoryNFT,
  toggleHeart,
  getNFTData,
  getNFTDataMarketplace,
  getLikedNfts,
  getAllCollections,
};

const mapStateToProps = ({ Auth, NFT }) => {
  let { userprofileData, likedNFTs } = Auth;
  let { allNFTData, categories, types, nftData, nftDataMarketplace, likedNftMarketplace, collectionsMarketplace } = NFT;
  return { allNFTData, categories, types, userprofileData, nftData, likedNFTs, nftDataMarketplace, likedNftMarketplace, collectionsMarketplace };
};

export default connect(mapStateToProps, mapDispatchToProps)(Category);