import EventBus from "eventing-bus";
import { connect } from "react-redux";
import React, { Component } from 'react';
import { TextField } from '@material-ui/core';
import { Link } from 'react-router-dom';
// import OurTeam from './ourTeam';
// import OwlCarousel from 'react-owl-carousel';

import './index.css';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { Redirect } from 'react-router'


class ErrorPage extends Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  };



  render() {


    return (
      <div className='profile-nft'>

        <Navbar />

        <section className="error-page" style={{ backgroundImage: `url(${require("../../static/images/landing/404.png")})` }}>
          <div className="auto-container">
            <div className="row">
              <div className="col-12">
                <h1>404</h1>
                <h4>This page is lost.</h4>
                <p>We've explored deep and wide, <br /> but we can't find the page you were looking for.</p>
                <Link to="/" className="btn-style-one">GO back <i className='icon fa fa-angle-right'></i></Link>
              </div>
            </div>
          </div>
        </section>

        <Footer />


      </div>
    );
  }

}


export default connect()(ErrorPage);