import { connect } from "react-redux";
import React, { Component, Fragment } from "react";

import "./index.css";
import data from './data.json'
import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import { web3 } from '../../store/contract'
import { getNFTData, getNFTDataMarketplace, setNFTDataMarketplace } from "../../store/actions/NFT";

class Activity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activity: "SELL",
      nftSoldData: [],
      nftTransferData: [],
      nftBidData: [],
    };
    props.getNFTDataMarketplace("SELL");
  };

  componentWillReceiveProps({ nftData, nftDataMarketplace }) {
    console.log("********nftDataMarketplace::", nftDataMarketplace);

    let { activity, nftSoldData, nftTransferData, nftBidData } = this.state;
    if (nftDataMarketplace && activity == "SELL") {
      let nftSoldArray = nftDataMarketplace['BidAccepted'].concat(nftDataMarketplace['OrderSuccessful']);
      for (let i = 0; i < nftSoldArray.length; i++) {
        nftSoldData.push(nftSoldArray[i]);
      }
    }
    if (nftDataMarketplace && activity == "BID") {
      console.log("*********nftDataMarketplace::", nftDataMarketplace['BidCreated']);
      for (let i = 0; i < nftDataMarketplace['BidCreated'].length; i++) {
        nftBidData.push(nftDataMarketplace['BidCreated'][i]);
      }
    }
    if (nftData) {
      for (let i = 0; i < nftData.length; i++) {
        nftTransferData.push(nftData[i]);
      }
    }
    this.setState({ nftSoldData, nftTransferData, nftBidData });

  };

  handleFilter = (activity) => {
    if (activity == 'TRANSFER') this.props.getNFTData(activity)
    if (activity == 'BID') this.props.getNFTDataMarketplace(activity);
    if (activity == 'SELL') this.props.getNFTDataMarketplace(activity);
    this.setState({ activity, nftSoldData: [], nftTransferData: [], nftBidData: [] });
  }

  render() {
    let { activity, nftSoldData, nftTransferData, nftBidData } = this.state;
    return (
      <div className="activitypage-nft">
        <Navbar />
        {/* Product Section */}
        <section className="activity-sec"
          style={{
            backgroundImage: `url(${require("../../static/images/landing/activity/activity-bg.png")})`,
          }}
        >
          <div className="auto-container">
            <div className="row">
              <div className="col-12">
                <div className="sec-title">
                  <h2>Activity</h2>
                </div>
              </div>
              <div className="activity-block col-lg-8 col-md-12">
                <div className="activity-table table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        {/* <th className="item">Item</th> */}
                        <th className="item">Event</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>From</th>
                        <th>To</th>
                        {/* <th>Time</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {activity === "SELL" &&
                        <Fragment>
                          {nftSoldData.length > 0
                            ? nftSoldData.map(data => {
                              return (
                                <tr>
                                  <td>
                                    <h4><a href="#"><p>{data?.event}</p></a></h4>
                                  </td>
                                  <td>
                                    <h4><a href="#"><p>{web3.utils.fromWei(data?.returnValues?.biddingPrice, 'ether')}</p></a></h4>
                                  </td>
                                  <td>
                                    <h4><a href="#"><p>1</p></a></h4>
                                  </td>
                                  <td>
                                    <h4><a href="#"><p>{data?.returnValues?.bidder.slice(0, 8)}...</p></a></h4>
                                  </td>
                                  <td>
                                    <h4><a href="#"><p>{data?.returnValues[3].slice(0, 8)}...</p></a></h4>
                                  </td>
                                </tr>
                              )
                            })
                            : <tr><p>No Data Founded!!!</p></tr>
                          }
                        </Fragment>
                      }

                      {activity === "TRANSFER" &&
                        <Fragment>
                          {nftTransferData.length > 0
                            ? nftTransferData.map(data => {
                              return (
                                <tr>
                                  <td>
                                    <h4><a href="#">{data['type'] || data['@type']}</a></h4>
                                  </td>
                                  <td>
                                    <p className="price">
                                      <i className="icon">
                                        <img
                                          src={require("../../static/images/landing/activity/eth-1.png")}
                                          alt=""
                                        />
                                      </i>{" "}
                                      {data['price']?.slice(0, 4) ? data['price']?.slice(0, 4) : "--"}
                                      {/* <span>${parseFloat(data['priceUsd']).toFixed(2)}</span> */}
                                    </p>
                                  </td>
                                  <td>
                                    <p>{data['@type'] == "LIST" ? data?.make?.value : data?.take?.value || data?.nft?.value || data?.value}</p>
                                  </td>
                                  <td>
                                    <p className="meta-name">{data['@type'] == "BURN" ? data['owner']?.slice(9, 18) : data['seller'] ? data['seller']?.slice(9, 18) : data['from'] ? data['from'].slice(9, 18) : data['maker'].slice(9, 18)}...</p>
                                  </td>
                                  <td>
                                    <p>{data['@type'] == "BURN" ? "..." : data['buyer']?.slice(9, 18) || data['owner']?.slice(9, 18)}...</p>
                                  </td>
                                </tr>
                              )
                            }
                            )
                            : <tr><p>No Data Founded!!!</p></tr>
                          }
                        </Fragment>
                      }

                      {activity === "BID" &&
                        <Fragment>
                          {nftBidData.length > 0
                            ? nftBidData.map(data => {
                              return (
                                <tr>
                                  <td>
                                    <h4><a href="#">{data['event']}</a></h4>
                                  </td>
                                  <td>
                                    <p className="price">
                                      <i className="icon">
                                        <img
                                          src={require("../../static/images/landing/activity/eth-1.png")}
                                          alt=""
                                        />
                                      </i>{" "}
                                      {web3.utils.fromWei(data?.returnValues?.biddingPrice,'ether')}
                                      {/* <span>${parseFloat(data['priceUsd']).toFixed(2)}</span> */}
                                    </p>
                                  </td>
                                  <td>
                                    <p>1</p>
                                  </td>
                                  <td>
                                    <p className="meta-name">{data['returnValues']['bidder'].slice(9, 18)}...</p>
                                  </td>
                                  <td>
                                    <p>---</p>
                                  </td>
                                </tr>
                              )
                            }
                            )
                            : <tr><p>No Data Founded!!!</p></tr>
                          }
                        </Fragment>
                      }

                    </tbody>
                  </table>
                </div>
              </div>

              <div className="buttons-block col-lg-4 col-md-12">
                <div className="buttons-inner">
                  <div className="filers-area">
                    <h5>Filters</h5>
                    <a className="reset-filters" >
                      <button onClick={() => this.handleFilter("SELL")}>
                        <a className="reset-filters">Reset Filter</a>
                      </button>
                    </a>
                  </div>
                  <div id="accordion">
                    <div class="card">
                      <div class="card-header" id="headingOne">
                        <h5 class="mb-0">
                          <button
                            class="red btn btn-link"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="false"
                            aria-controls="collapseOne"
                          >
                            Event Type
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapseOne"
                        class="collapse show"
                        aria-labelledby="headingOne"
                        data-parent="#accordion"
                      >
                        <div class="card-body">
                          <ul className="filter-list">
                            <li
                              className={`purchase ${activity == "SELL" && "active"
                                }`}
                            >
                              <button onClick={() => this.handleFilter("SELL")}>
                                <i className="icon fa fa-dollar"></i>Sold
                              </button>
                            </li>
                            <li
                              className={`transfer ${activity == "TRANSFER" && "active"
                                }`}
                            >
                              <button
                                onClick={() => this.handleFilter("TRANSFER")}
                              >
                                <i className="icon fa fa-exchange"></i> Transfer
                              </button>
                            </li>
                            <li
                              className={`bids ${activity == "BID" && "active"
                                }`}
                            >
                              <button onClick={() => this.handleFilter("BID")}>
                                <i className="icon fa fa-gavel"></i> Bids
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section >
        <Footer />
      </div >
    );
  }
}

const mapDispatchToProps = { getNFTData, getNFTDataMarketplace, setNFTDataMarketplace };

const mapStateToProps = ({ NFT }) => {
  let { nftData, nftDataMarketplace } = NFT;
  return { nftData, nftDataMarketplace };
};

export default connect(mapStateToProps, mapDispatchToProps)(Activity);
